// import { useState } from "react";
// import './AddAgentWizard.css';

// export default function AddAgentWizard() {
//   const [step, setStep] = useState(1);
//   const [agentName, setAgentName] = useState("");
//   const [agentType, setAgentType] = useState("QNA");
//   const [uploadType, setUploadType] = useState("pdf");
//   const [trainingData, setTrainingData] = useState("");

//   const handleNextStep = () => {
//     if (step === 1 && !agentName) {
//       alert("Please enter an agent name");
//       return;
//     }
//     if (step === 2) {
//       setTimeout(() => setStep(3), Math.random() * 2000); // Simulate server response
//     } else {
//       setStep(step + 1);
//     }
//   };

//   const handleSubmit = () => {
//     console.log({ agentName, agentType, uploadType, trainingData });
//     alert("Your Agent Created Successfully. You Can test the conversation From Agent Screen");
//   };

//   return (
//     <div className="wizard-container">
//       <h2 className="step-title">Add New Agent - Step {step}</h2>
//       {step === 1 && (
//         <div className="step-content">
//           <div>
//             <label htmlFor="agentName" className="input-label">
//               Agent Name
//             </label>
//             <input
//               id="agentName"
//               type="text"
//               className="input-field"
//               value={agentName}
//               onChange={(e) => setAgentName(e.target.value)}
//             />
//           </div>
//           <div>
//             <p className="input-label">Agent Type</p>
//             <div className="radio-group">
//               <div>
//                 <input
//                   type="radio"
//                   id="qna"
//                   name="agentType"
//                   value="QNA"
//                   checked={agentType === "QNA"}
//                   onChange={(e) => setAgentType(e.target.value)}
//                   className="radio-input"
//                 />
//                 <label htmlFor="qna" className="radio-label">
//                   QNA Agent
//                 </label>
//               </div>
//               <div>
//                 <input
//                   type="radio"
//                   id="actionable"
//                   name="agentType"
//                   value="Actionable"
//                   checked={agentType === "Actionable"}
//                   onChange={(e) => setAgentType(e.target.value)}
//                   className="radio-input"
//                 />
//                 <label htmlFor="actionable" className="radio-label">
//                   Actionable Agent
//                 </label>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//       {step === 2 && (
//         <div className="loading-step">
//           <div className="loading-spinner"></div>
//           <p>Please wait. Agent is being created...</p>
//         </div>
//       )}
//       {step === 3 && (
//         <div className="step-content">
//           <p className="input-label">Upload Knowledge Base</p>
//           <div className="radio-group">
//             <div>
//               <input
//                 type="radio"
//                 id="pdf"
//                 name="uploadType"
//                 value="pdf"
//                 checked={uploadType === "pdf"}
//                 onChange={(e) => setUploadType(e.target.value)}
//                 className="radio-input"
//               />
//               <label htmlFor="pdf" className="radio-label">
//                 Upload PDF
//               </label>
//             </div>
//             <div>
//               <input
//                 type="radio"
//                 id="text"
//                 name="uploadType"
//                 value="text"
//                 checked={uploadType === "text"}
//                 onChange={(e) => setUploadType(e.target.value)}
//                 className="radio-input"
//               />
//               <label htmlFor="text" className="radio-label">
//                 Paste Text
//               </label>
//             </div>
//             <div>
//               <input
//                 type="radio"
//                 id="url"
//                 name="uploadType"
//                 value="url"
//                 checked={uploadType === "url"}
//                 onChange={(e) => setUploadType(e.target.value)}
//                 className="radio-input"
//               />
//               <label htmlFor="url" className="radio-label">
//                 Use URL
//               </label>
//             </div>
//           </div>
//           {uploadType === "text" && (
//             <textarea
//               placeholder="Paste your text here"
//               value={trainingData}
//               onChange={(e) => setTrainingData(e.target.value)}
//               className="textarea-field"
//             />
//           )}
//           {uploadType === "pdf" && (
//             <input
//               type="file"
//               accept=".pdf"
//               onChange={(e) => setTrainingData(e.target.files?.[0]?.name || "")}
//               className="file-input"
//             />
//           )}
//           {uploadType === "url" && (
//             <input
//               type="url"
//               placeholder="Enter URL"
//               value={trainingData}
//               onChange={(e) => setTrainingData(e.target.value)}
//               className="input-field"
//             />
//           )}
//         </div>
//       )}
//       <div className="button-group">
//         {step > 1 && (
//           <button
//             className="back-button"
//             onClick={() => setStep(step - 1)}
//           >
//             Back
//           </button>
//         )}
//         {step < 3 ? (
//           <button
//             className="next-button"
//             onClick={handleNextStep}
//           >
//             Next
//           </button>
//         ) : (
//           <button
//             className="submit-button"
//             onClick={handleSubmit}
//           >
//             Submit
//           </button>
//         )}
//       </div>
//     </div>
//   );
// }

import { useState } from "react";
import "./AddAgentWizard.css";

export default function AddAgentWizard() {
  const [step, setStep] = useState(1);
  const [agentName, setAgentName] = useState("");
  const [agentType, setAgentType] = useState("QNA"); // Default to QNA
  const [uploadType, setUploadType] = useState("pdf");
  const [trainingData, setTrainingData] = useState("");

  const handleNextStep = () => {
    if (step === 1 && !agentName) {
      alert("Please enter an agent name");
      return;
    }
    if (step === 2) {
      setTimeout(() => setStep(3), Math.random() * 2000); // Simulate server response
    } else {
      setStep(step + 1);
    }
  };

  const handleSubmit = () => {
    console.log({ agentName, agentType, uploadType, trainingData });
    alert(
      "Your Agent Created Successfully. You Can test the conversation From Agent Screen"
    );
  };

  return (
    <div className="wizard-container">
      <h2 className="step-title">Add New Agent - Step {step}</h2>
      {step === 1 && (
        <div className="step-content">
          <div>
            <label htmlFor="agentName" className="input-label">
              Agent Name
            </label>
            <br />
            <input
              id="agentName"
              type="text"
              className="input_field"
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
            />
          </div>
          <div>
            <p className="input-label">Agent Type</p>
            <div className="checkbox-group">
              <div className="checkbox-inner">
                <input
                  type="checkbox"
                  id="qna"
                  name="agentType"
                  value="QNA"
                  checked={agentType === "QNA"}
                  onChange={(e) => setAgentType(e.target.checked ? "QNA" : "")}
                  className="checkbox-input"
                />
                <label htmlFor="qna" className="checkbox-label">
                  QNA Agent
                </label>
              </div>
              <div className="checkbox-inner">
                <input
                  type="checkbox"
                  id="actionable"
                  name="agentType"
                  value="Actionable"
                  checked={agentType === "Actionable"}
                  onChange={(e) =>
                    setAgentType(e.target.checked ? "Actionable" : "")
                  }
                  className="checkbox-input"
                />
                <label htmlFor="actionable" className="checkbox-label">
                  Actionable Agent
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="loading-step">
          <div className="loading-spinner"></div>
          <p>Please wait. Agent is being created...</p>
        </div>
      )}
      {step === 3 && (
        <div className="step-content">
          <p className="input-label">Upload Knowledge Base</p>
          <div className="checkbox-group">
            <div className="checkbox-inner">
              <input
                type="checkbox"
                id="pdf"
                name="uploadType"
                value="pdf"
                checked={uploadType === "pdf"}
                onChange={(e) => setUploadType(e.target.checked ? "pdf" : "")}
                className="checkbox-input"
              />
              <label htmlFor="pdf" className="checkbox-label">
                Upload PDF
              </label>
            </div>
            <div className="checkbox-inner">
              <input
                type="checkbox"
                id="text"
                name="uploadType"
                value="text"
                checked={uploadType === "text"}
                onChange={(e) => setUploadType(e.target.checked ? "text" : "")}
                className="checkbox-input"
              />
              <label htmlFor="text" className="checkbox-label">
                Paste Text
              </label>
            </div>
            <div className="checkbox-inner">
              <input
                type="checkbox"
                id="url"
                name="uploadType"
                value="url"
                checked={uploadType === "url"}
                onChange={(e) => setUploadType(e.target.checked ? "url" : "")}
                className="checkbox-input"
              />
              <label htmlFor="url" className="checkbox-label">
                Use URL
              </label>
            </div>
          </div>
          {uploadType === "text" && (
            <textarea
              placeholder="Paste your text here"
              value={trainingData}
              onChange={(e) => setTrainingData(e.target.value)}
              className="textarea-field"
            />
          )}
          {uploadType === "pdf" && (
            <input
              type="file"
              accept=".pdf"
              onChange={(e) => setTrainingData(e.target.files?.[0]?.name || "")}
              className="file-input"
            />
          )}
          <br/>
          {uploadType === "url" && (
            <input
              type="url"
              placeholder="Enter URL"
              value={trainingData}
              onChange={(e) => setTrainingData(e.target.value)}
              className="input_field"
            />
          )}
        </div>
      )}

      <div className="button-group">
        {step > 1 && (
          <button className="back-button" onClick={() => setStep(step - 1)}>
            Back
          </button>
        )}
        {step < 3 ? (
          <button className="next-button" onClick={handleNextStep}>
            Next
          </button>
        ) : (
          <button className="submit-button" onClick={handleSubmit}>
            Submit
          </button>
        )}
      </div>
    </div>
  );
}
