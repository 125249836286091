// import React, { useState, useEffect, useCallback } from 'react';
// import { Link } from 'react-router-dom';
// import './mainboard.css';

// export default function TransactionHistory() {
//   const [transactions, setTransactions] = useState([]);
//   const [displayedTransactions, setDisplayedTransactions] = useState([]);
//   const [transactionPage, setTransactionPage] = useState(1);
//   const [selectedTransactions, setSelectedTransactions] = useState(new Set());
//   const [selectAllTransactions, setSelectAllTransactions] = useState(false);

//   const transactionsPerPage = 10; // Updated pagination size

//   useEffect(() => {
//     fetchTransactions(transactionPage);
//   }, [transactionPage]);

//   const fetchTransactions = async (pageNumber) => {
//     try {
//       const accessToken = localStorage.getItem('access_token');

//       if (!accessToken) {
//         console.error('No access token found in local storage');
//         return;
//       }

//       const response = await fetch(
//         `https://devapi.ivoz.ai/users/transactions?page=${pageNumber}&per_page=${transactionsPerPage}`,
//         {
//           headers: {
//             accept: 'application/json',
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         setTransactions((prev) => [...prev, ...data]);
//       } else {
//         console.error('Failed to fetch transactions');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   const updateDisplayedTransactions = useCallback(() => {
//     const startIndex = (transactionPage - 1) * transactionsPerPage;
//     const endIndex = startIndex + transactionsPerPage;
//     setDisplayedTransactions(transactions.slice(startIndex, endIndex));
//   }, [transactionPage, transactions]);

//   useEffect(() => {
//     updateDisplayedTransactions();
//   }, [updateDisplayedTransactions]);

//   const handleNextTransactions = () => {
//     if (transactionPage * transactionsPerPage < transactions.length) {
//       setTransactionPage((prevPage) => prevPage + 1);
//     }
//   };

//   const handlePreviousTransactions = () => {
//     if (transactionPage > 1) {
//       setTransactionPage((prevPage) => prevPage - 1);
//     }
//   };

//   const handleSelectTransaction = (id) => {
//     setSelectedTransactions((prevSelected) => {
//       const newSelected = new Set(prevSelected);
//       if (newSelected.has(id)) {
//         newSelected.delete(id);
//       } else {
//         newSelected.add(id);
//       }
//       return newSelected;
//     });
//   };

//   const handleSelectAllTransactions = () => {
//     setSelectAllTransactions((prevSelectAll) => {
//       const newSelectAll = !prevSelectAll;
//       setSelectedTransactions(newSelectAll
//         ? new Set(displayedTransactions.map((txn) => txn.id))
//         : new Set()
//       );
//       return newSelectAll;
//     });
//   };

//   const downloadCSV = (data, filename) => {
//     const csvContent = data.map((e) => e.join(",")).join("\n");
//     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.setAttribute('download', filename);
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   const handleDownloadTransactions = () => {
//     if (selectedTransactions.size === 0) {
//       return; // Do nothing if no transactions are selected
//     }
//     const filteredTransactions = displayedTransactions.filter((txn) => selectedTransactions.has(txn.id));
//     const csvData = [['Sr No', 'ID', 'Type', 'Amount', 'Date']].concat(
//       filteredTransactions.map((txn, index) => [
//         (transactionPage - 1) * transactionsPerPage + index + 1,
//         txn.id, // Include the ID
//         txn.txn_type,
//         txn.amount,
//         new Date(txn.created_on).toLocaleDateString()
//       ])
//     );
//     downloadCSV(csvData, 'transactions.csv');
//   };

//   const isDownloadButtonDisabled = selectedTransactions.size === 0;

//   return (
//     <>
//       <div>
//         <h1>Transaction History</h1>
//       </div>
//       <div className="transaction-table">
//         <div className="history">
//           <div className="tableUpr">
//             <h2>Transaction History</h2>
//             <button
//               className='searchSubmit'
//               onClick={handleDownloadTransactions}
//               disabled={isDownloadButtonDisabled}
//             >
//               Download CSV
//             </button>
//           </div>
//           <table>
//             <thead>
//               <tr>
//                 <th>
//                   <input
//                     type="checkbox"
//                     checked={selectAllTransactions}
//                     onChange={handleSelectAllTransactions}
//                   />
//                 </th>
//                 <th>Sr No</th>
//                 <th>ID</th> {/* Add the ID column */}
//                 <th>Type</th>
//                 <th>Amount</th>
//                 <th>Date</th>
//               </tr>
//             </thead>
//             <tbody>
//               {displayedTransactions.map((txn, index) => (
//                 <tr key={txn.id}>
//                   <td>
//                     <input
//                       type="checkbox"
//                       checked={selectedTransactions.has(txn.id)}
//                       onChange={() => handleSelectTransaction(txn.id)}
//                     />
//                   </td>
//                   <td>{(transactionPage - 1) * transactionsPerPage + index + 1}</td>
//                   <td>{txn.id}</td> {/* Display the transaction ID */}
//                   <td>{txn.txn_type}</td>
//                   <td>${txn.amount}</td>
//                   <td>{new Date(txn.created_on).toLocaleDateString()}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           <div className='nextPrev'>
//             {transactionPage > 1 && (
//               <Link to="#" onClick={handlePreviousTransactions}>
//                 Previous 10 Transactions
//               </Link>
//             )}
//             {transactionPage * transactionsPerPage < transactions.length && (
//               <Link to="#" onClick={handleNextTransactions}>
//                 Next 10 Transactions
//               </Link>
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }



import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './mainboard.css';

export default function TransactionHistory() {
  const [transactions, setTransactions] = useState([]);
  const [displayedTransactions, setDisplayedTransactions] = useState([]);
  const [transactionPage, setTransactionPage] = useState(1);
  const [selectedTransactions, setSelectedTransactions] = useState(new Set());
  const [selectAllTransactions, setSelectAllTransactions] = useState(false);
  const navigate = useNavigate(); // For navigation

  const transactionsPerPage = 10; // Updated pagination size

  const fetchTransactions = useCallback(async (pageNumber, accessToken) => {
    try {
      const response = await fetch(
        `https://devapi.ivoz.ai/users/transactions?page=${pageNumber}&per_page=${transactionsPerPage}`,
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setTransactions((prev) => [...prev, ...data]);
      } else if (response.status === 401) {
        console.error('Invalid or expired token');
        localStorage.removeItem('access_token'); // Clear invalid token
        navigate('/'); // Redirect to login
      } else {
        console.error('Failed to fetch transactions');
      }
    } catch (error) {
      console.error('Error:', error);
      navigate('/'); // Redirect to login on fetch error
    }
  }, [navigate, transactionsPerPage]);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');

    // Redirect to login if no access token
    if (!accessToken) {
      console.error('No access token found in local storage');
      navigate('/');
      return;
    }

    fetchTransactions(transactionPage, accessToken);
  }, [transactionPage, navigate, fetchTransactions]);

  const updateDisplayedTransactions = useCallback(() => {
    const startIndex = (transactionPage - 1) * transactionsPerPage;
    const endIndex = startIndex + transactionsPerPage;
    setDisplayedTransactions(transactions.slice(startIndex, endIndex));
  }, [transactionPage, transactions]);

  useEffect(() => {
    updateDisplayedTransactions();
  }, [updateDisplayedTransactions]);

  const handleNextTransactions = () => {
    if (transactionPage * transactionsPerPage < transactions.length) {
      setTransactionPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousTransactions = () => {
    if (transactionPage > 1) {
      setTransactionPage((prevPage) => prevPage - 1);
    }
  };

  const handleSelectTransaction = (id) => {
    setSelectedTransactions((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const handleSelectAllTransactions = () => {
    setSelectAllTransactions((prevSelectAll) => {
      const newSelectAll = !prevSelectAll;
      setSelectedTransactions(newSelectAll
        ? new Set(displayedTransactions.map((txn) => txn.id))
        : new Set()
      );
      return newSelectAll;
    });
  };

  const downloadCSV = (data, filename) => {
    const csvContent = data.map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadTransactions = () => {
    if (selectedTransactions.size === 0) {
      return; // Do nothing if no transactions are selected
    }
    const filteredTransactions = displayedTransactions.filter((txn) => selectedTransactions.has(txn.id));
    const csvData = [['Sr No', 'ID', 'Type', 'Amount', 'Date']].concat(
      filteredTransactions.map((txn, index) => [
        (transactionPage - 1) * transactionsPerPage + index + 1,
        txn.id, // Include the ID
        txn.txn_type,
        txn.amount,
        new Date(txn.created_on).toLocaleDateString()
      ])
    );
    downloadCSV(csvData, 'transactions.csv');
  };

  const isDownloadButtonDisabled = selectedTransactions.size === 0;

  return (
    <>
      <div>
        <h1>Transaction History</h1>
      </div>
      <div className="transaction-table">
        <div className="history">
          <div className="tableUpr">
            <h2>Transaction History</h2>
            <button
              className='searchSubmit'
              onClick={handleDownloadTransactions}
              disabled={isDownloadButtonDisabled}
            >
              Download CSV
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectAllTransactions}
                    onChange={handleSelectAllTransactions}
                  />
                </th>
                <th>Sr No</th>
                <th>ID</th> {/* Add the ID column */}
                <th>Type</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {displayedTransactions.map((txn, index) => (
                <tr key={txn.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedTransactions.has(txn.id)}
                      onChange={() => handleSelectTransaction(txn.id)}
                    />
                  </td>
                  <td>{(transactionPage - 1) * transactionsPerPage + index + 1}</td>
                  <td>{txn.id}</td> {/* Display the transaction ID */}
                  <td>{txn.txn_type}</td>
                  <td>${txn.amount}</td>
                  <td>{new Date(txn.created_on).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='nextPrev'>
            {transactionPage > 1 && (
              <Link to="#" onClick={handlePreviousTransactions}>
                Previous 10 Transactions
              </Link>
            )}
            {transactionPage * transactionsPerPage < transactions.length && (
              <Link to="#" onClick={handleNextTransactions}>
                Next 10 Transactions
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
