// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom"; // Import navigate hook for redirection
// import "./mainboard.css";
// import "./campaign.css";

// export default function Campaigns() {
//   const navigate = useNavigate(); // Hook to navigate

//   const [campaigns, setCampaigns] = useState([]); // State to store campaigns data
//   const [newCampaignName, setNewCampaignName] = useState("");
//   const [newProjectId, setNewProjectId] = useState(""); // State for Project ID
//   const [selectedFile, setSelectedFile] = useState(null); // State for selected file
//   const [campaignId, setCampaignId] = useState(""); // State for campaign ID
//   const [currentStep, setCurrentStep] = useState(0); // State to manage current step

//   useEffect(() => {
//     // Define the checkToken function inside the useEffect
//     const checkToken = () => {
//       const token = localStorage.getItem("access_token");
//       if (!token) {
//         navigate("/"); // Navigate to "/" if no token is found
//         return false;
//       }

//       // Optionally, you can check if the token is valid by making an API call
//       return true; // In this case, just assume the token exists and is valid
//     };

//     // Check token validity before fetching campaigns
//     if (!checkToken()) {
//       return; // Exit if the token is invalid or missing
//     }

//     // Fetch campaigns data from API
//     const fetchCampaigns = async () => {
//       try {
//         const token = localStorage.getItem("access_token"); // Retrieve token from localStorage
//         const response = await fetch(
//           "https://devapi.ivoz.ai/campaigns/?page=1&per_page=20",
//           {
//             method: "GET",
//             headers: {
//               accept: "application/json",
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         if (!response.ok) {
//           throw new Error("Failed to fetch campaigns");
//         }
//         const data = await response.json();
//         setCampaigns(data || []); // Update state with fetched campaigns
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     fetchCampaigns();
//   }, [navigate]); // Only depend on navigate, as checkToken is now inside useEffect

//   // Handle creating a campaign
//   const handleCreateCampaign = async () => {
//     try {
//       const token = localStorage.getItem("access_token"); // Retrieve token from localStorage
//       if (!token) {
//         throw new Error("Authorization token not found");
//       }

//       const response = await fetch("https://devapi.ivoz.ai/campaigns/", {
//         method: "POST",
//         headers: {
//           accept: "application/json",
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           bot_id: newProjectId,
//           name: newCampaignName,
//           project_id: newProjectId,
//         }),
//       });

//       if (!response.ok) {
//         throw new Error("Failed to create campaign");
//       }

//       const data = await response.json();
//       if (data.status) {
//         alert("Campaign created successfully!");
//         setCampaigns([
//           ...campaigns,
//           {
//             id: data.campaign_id,
//             name: newCampaignName,
//             bot_id: 2,
//             status: "started",
//             created_on: new Date(),
//           },
//         ]);
//         setCurrentStep(2); // Move to step 2
//       } else {
//         alert("Error creating campaign");
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   // Handle file upload
//   const handleUploadFile = async () => {
//     if (!selectedFile || !campaignId) {
//       alert("Please select a file and provide a campaign ID.");
//       return;
//     }

//     const token = localStorage.getItem("access_token");
//     if (!token) {
//       throw new Error("Authorization token not found");
//     }

//     const formData = new FormData();
//     formData.append("csv_file", selectedFile);

//     try {
//       const response = await fetch(
//         `https://devapi.ivoz.ai/campaigns/upload-file/${campaignId}`,
//         {
//           method: "POST",
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//           body: formData,
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to upload file");
//       }

//       const data = await response.json();
//       if (data.status) {
//         alert("File Uploaded Successfully");
//       } else {
//         alert("Error uploading file");
//       }
//     } catch (error) {
//       console.error(error);
//       alert("Error uploading file");
//     }
//   };

//   const handleToggleStatus = async (id, currentStatus) => {
//     try {
//       const newStatus = currentStatus === "started" ? "stopped" : "started";

//       const token = localStorage.getItem("access_token"); // Retrieve token from localStorage
//       if (!token) {
//         throw new Error("Authorization token not found");
//       }

//       const response = await fetch(
//         `https://devapi.ivoz.ai/campaigns/update-status/${id}?status=${newStatus}`,
//         {
//           method: "POST",
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to update campaign status");
//       }

//       const updatedCampaigns = campaigns.map((campaign) => {
//         if (campaign.id === id) {
//           return { ...campaign, status: newStatus };
//         }
//         return campaign;
//       });

//       setCampaigns(updatedCampaigns); // Update state with the new status
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <div className="campaign-page">
//       {/* Button to start the campaign creation process */}
//       {currentStep === 0 && (
//         <button
//           className="campaign-btn create-btn"
//           onClick={() => setCurrentStep(1)}
//         >
//           Create Campaign
//         </button>
//       )}

//       <div className="campaign-steps">
//         {/* Conditional rendering based on the current step */}
//         {currentStep === 1 && (
//           <div className="campaign-setups">
//             <h2>Create Campaign: Step 1</h2>
//             <div className="campaign-setup-inner">
//               <label htmlFor="Campaign Name">Campaign Name</label>
//               <input
//                 type="text"
//                 placeholder="Campaign Name"
//                 value={newCampaignName}
//                 onChange={(e) => setNewCampaignName(e.target.value)}
//               />
//               <label htmlFor="Agent ID">Agent ID</label>
//               <input
//                 type="text"
//                 placeholder="Agent ID"
//                 value={newProjectId}
//                 onChange={(e) => setNewProjectId(e.target.value)}
//               />
//               <button className="campaign-btn" onClick={handleCreateCampaign}>
//                 Create
//               </button>
//             </div>
//           </div>
//         )}

//         {currentStep === 2 && (
//           <div className="campaign-setups">
//              <h2>Create Campaign: Step 2</h2>
//             <div className="campaign-setup-inner">
//               <input
//                 type="file"
//                 accept=".csv"
//                 onChange={(e) => setSelectedFile(e.target.files[0])}
//               />
//               <select
//                 value={campaignId}
//                 onChange={(e) => setCampaignId(e.target.value)}
//               >
//                 <option value="">Select Campaign</option>
//                 {campaigns.map((campaign) => (
//                   <option key={campaign.id} value={campaign.id}>
//                     {campaign.name}
//                   </option>
//                 ))}
//               </select>
//               <div className="button-group">
//                 <button
//                   className="campaign-btn"
//                   onClick={() => setCurrentStep(1)}
//                 >
//                   Previous
//                 </button>
//                 <button className="campaign-btn" onClick={handleUploadFile}>
//                   Submit
//                 </button>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>

//       {/* Campaign Table */}
//       <div className="campaign-table">
//         <h2>Campaigns</h2>
//         <table>
//           <thead>
//             <tr>
//               <th>Campaign ID</th>
//               <th>Campaign Name</th>
//               <th>Agent ID</th>
//               <th>Created On</th>
//               <th>Status</th>
//               <th>Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {campaigns.map((campaign, index) => (
//               <tr key={index}>
//                 <td>{campaign.id}</td>
//                 <td>{campaign.name}</td>
//                 <td>{campaign.bot_id}</td>
//                 <td>{new Date(campaign.created_on).toLocaleString()}</td>
//                 <td
//                   className={
//                     campaign.status === "started"
//                       ? "success"
//                       : campaign.status === "stopped"
//                       ? "danger"
//                       : ""
//                   }
//                 >
//                   {campaign.status}
//                 </td>
//                 <td>
//                   <button
//                     className={
//                       campaign.status === "started"
//                         ? "stop-button"
//                         : "start-button"
//                     }
//                     onClick={() =>
//                       handleToggleStatus(campaign.id, campaign.status)
//                     }
//                   >
//                     {campaign.status === "started" ? "STOP" : "START"}
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }




import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import navigate hook for redirection
import "./mainboard.css";
import "./campaign.css";

export default function Campaigns() {
  const navigate = useNavigate(); // Hook to navigate

  const [campaigns, setCampaigns] = useState([]); // State to store campaigns data
  const [newCampaignName, setNewCampaignName] = useState("");
  const [newProjectId, setNewProjectId] = useState(""); // State for Project ID
  const [selectedFile, setSelectedFile] = useState(null); // State for selected file
  const [campaignId, setCampaignId] = useState(""); // State for campaign ID
  const [currentStep, setCurrentStep] = useState(0); // State to manage current step
   const [currentPage, setCurrentPage] = useState(1); // For pagination
    const rowsPerPage = 10; // Rows per page

  useEffect(() => {
    // Define the checkToken function inside the useEffect
    const checkToken = () => {
      const token = localStorage.getItem("access_token");
      if (!token) {
        navigate("/"); // Navigate to "/" if no token is found
        return false;
      }

      // Optionally, you can check if the token is valid by making an API call
      return true; // In this case, just assume the token exists and is valid
    };

    // Check token validity before fetching campaigns
    if (!checkToken()) {
      return; // Exit if the token is invalid or missing
    }

    // Fetch campaigns data from API
   
    const fetchCampaigns = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await fetch(
          "https://devapi.ivoz.ai/campaigns/?page=1&per_page=20",
          {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch campaigns");
        }
        const data = await response.json();
        setCampaigns(data || []);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCampaigns();
  }, [navigate]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentCampaigns = campaigns.slice(
    startIndex,
    startIndex + rowsPerPage
  );

  const totalPages = Math.ceil(campaigns.length / rowsPerPage);

  // Handle creating a campaign
  const handleCreateCampaign = async () => {
    try {
      const token = localStorage.getItem("access_token"); // Retrieve token from localStorage
      if (!token) {
        throw new Error("Authorization token not found");
      }

      const response = await fetch("https://devapi.ivoz.ai/campaigns/", {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bot_id: newProjectId,
          name: newCampaignName,
          project_id: newProjectId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create campaign");
      }

      const data = await response.json();
      if (data.status) {
        alert("Campaign created successfully!");
        setCampaigns([
          ...campaigns,
          {
            id: data.campaign_id,
            name: newCampaignName,
            bot_id: 2,
            status: "started",
            created_on: new Date(),
          },
        ]);
        setCurrentStep(2); // Move to step 2
      } else {
        alert("Error creating campaign");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Handle file upload
  const handleUploadFile = async () => {
    if (!selectedFile || !campaignId) {
      alert("Please select a file and provide a campaign ID.");
      return;
    }

    const token = localStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authorization token not found");
    }

    const formData = new FormData();
    formData.append("csv_file", selectedFile);

    try {
      const response = await fetch(
        `https://devapi.ivoz.ai/campaigns/upload-file/${campaignId}`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const data = await response.json();
      if (data.status) {
        alert("File Uploaded Successfully");
      } else {
        alert("Error uploading file");
      }
    } catch (error) {
      console.error(error);
      alert("Error uploading file");
    }
  };

  const handleToggleStatus = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus === "started" ? "stopped" : "started";

      const token = localStorage.getItem("access_token"); // Retrieve token from localStorage
      if (!token) {
        throw new Error("Authorization token not found");
      }

      const response = await fetch(
        `https://devapi.ivoz.ai/campaigns/update-status/${id}?status=${newStatus}`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update campaign status");
      }

      const updatedCampaigns = campaigns.map((campaign) => {
        if (campaign.id === id) {
          return { ...campaign, status: newStatus };
        }
        return campaign;
      });

      setCampaigns(updatedCampaigns); // Update state with the new status
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="campaign-page">
       {/* Button to start the campaign creation process */}
      {currentStep === 0 && (
        <button
          className="campaign-btn create-btn"
          onClick={() => setCurrentStep(1)}
        >
          Create Campaign
        </button>
      )}

      <div className="campaign-steps">
        {/* Conditional rendering based on the current step */}
        {currentStep === 1 && (
          <div className="campaign-setups">
            <h2>Create Campaign: Step 1</h2>
            <div className="campaign-setup-inner">
              <label htmlFor="Campaign Name">Campaign Name</label>
              <input
                type="text"
                placeholder="Campaign Name"
                value={newCampaignName}
                onChange={(e) => setNewCampaignName(e.target.value)}
              />
              <label htmlFor="Agent ID">Agent ID</label>
              <input
                type="text"
                placeholder="Agent ID"
                value={newProjectId}
                onChange={(e) => setNewProjectId(e.target.value)}
              />
              <button className="campaign-btn" onClick={handleCreateCampaign}>
                Create
              </button>
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div className="campaign-setups">
             <h2>Create Campaign: Step 2</h2>
            <div className="campaign-setup-inner">
              <input
                type="file"
                accept=".csv"
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />
              <select
                value={campaignId}
                onChange={(e) => setCampaignId(e.target.value)}
              >
                <option value="">Select Campaign</option>
                {campaigns.map((campaign) => (
                  <option key={campaign.id} value={campaign.id}>
                    {campaign.name}
                  </option>
                ))}
              </select>
              <div className="button-group">
                <button
                  className="campaign-btn"
                  onClick={() => setCurrentStep(1)}
                >
                  Previous
                </button>
                <button className="campaign-btn" onClick={handleUploadFile}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="campaign-table">
        <h2>Campaigns</h2>
        <table>
          <thead>
            <tr>
              <th>Campaign ID</th>
              <th>Campaign Name</th>
              <th>Agent ID</th>
              <th>Created On</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentCampaigns.map((campaign, index) => (
              <tr key={index}>
                <td>{campaign.id}</td>
                <td>{campaign.name}</td>
                <td>{campaign.bot_id}</td>
                <td>{new Date(campaign.created_on).toLocaleString()}</td>
                <td
                  className={
                    campaign.status === "started"
                      ? "success"
                      : campaign.status === "stopped"
                      ? "danger"
                      : ""
                  }
                >
                  {campaign.status}
                </td>
                <td>
                  <button
                    className={
                      campaign.status === "started"
                        ? "stop-button"
                        : "start-button"
                    }
                    onClick={() =>
                      handleToggleStatus(campaign.id, campaign.status)
                    }
                  >
                    {campaign.status === "started" ? "STOP" : "START"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination Controls */}
       {/* Pagination Controls */}
<div className="pagination">
  <button
    className={`pagination-button ${currentPage === 1 ? "disabled" : ""}`}
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
  >
    Previous
  </button>
  <span className="pagination-info">
    Page {currentPage} of {totalPages}
  </span>
  <button
    className={`pagination-button ${currentPage === totalPages ? "disabled" : ""}`}
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
  >
    Next
  </button>
</div>

      </div>
    </div>
  );
}