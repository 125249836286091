// import React, { useState, useEffect, useCallback } from "react";
// import { useNavigate } from "react-router-dom";

// export default function AdminHome() {
//   const [users, setUsers] = useState([]);
//   const [dashboardData, setDashboardData] = useState({
//     total_balance: 0,
//     total_expense: 0,
//   });
//   const navigate = useNavigate();

//   // Use useCallback to memoize the fetchUsers function
//   const fetchUsers = useCallback(async () => {
//     try {
//       const accessToken = localStorage.getItem("access_token");

//       if (!accessToken) {
//         console.error("No access token found in local storage");
//         navigate("/"); // Redirect to home page if no token is found
//         return;
//       }

//       const response = await fetch(
//         "https://devapi.ivoz.ai/admin/users?page=1&per_page=20",
//         {
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         setUsers(data); // Store the user data in the state
//       } else {
//         console.error("Failed to fetch users");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   }, [navigate]);

//   // Fetch dashboard analytics data
//   const fetchDashboardAnalytics = useCallback(async () => {
//     try {
//       const accessToken = localStorage.getItem("access_token");

//       if (!accessToken) {
//         console.error("No access token found in local storage");
//         navigate("/"); // Redirect to home page if no token is found
//         return;
//       }

//       const response = await fetch(
//         "https://devapi.ivoz.ai/admin/dashboard-analytics",
//         {
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         setDashboardData({
//           total_balance: data.total_balance,
//           total_expense: data.total_expense,
//         });
//       } else {
//         console.error("Failed to fetch dashboard analytics");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   }, [navigate]);

//   // Call fetchUsers and fetchDashboardAnalytics on component mount
//   useEffect(() => {
//     fetchUsers();
//     fetchDashboardAnalytics();
//   }, [fetchUsers, fetchDashboardAnalytics]);

//   return (
//     <>
//       <div className="main-inner">
//         <div className="upperMiddle">
//           <h1>Dashboard</h1>
//         </div>
//         <div className="insights">
//           {/* Account Balance */}
//           <div className="accountBal">
//             <span className="material-icons-sharp">analytics</span>
//             <div className="middle">
//               <div className="left">
//                 <h3>Account Balance</h3>
//                 <h1>${dashboardData.total_balance.toFixed(2)}</h1>
//               </div>
//               <div className="progress">
//                 <svg>
//                   <circle cx="38" cy="38" r="36"></circle>
//                 </svg>
//                 <div className="number">
//                   <p>81%</p>
//                 </div>
//               </div>
//             </div>
//             <small className="text-muted">Last 24 hours</small>
//           </div>
//           {/* END OF Account Balance */}

//           {/* On Hold Balance */}
//           <div className="onHoldBal">
//             <span className="material-icons-sharp">bar_chart</span>
//             <div className="middle">
//               <div className="left">
//                 <h3>On-hold Balance</h3>
//                 <h1>N/A</h1>
//               </div>
//               <div className="progress">
//                 <svg>
//                   <circle cx="38" cy="38" r="36"></circle>
//                 </svg>
//                 <div className="number">
//                   <p>41%</p>
//                 </div>
//               </div>
//             </div>
//             <small className="text-muted">Last 24 hours</small>
//           </div>
//           {/* END OF On Hold Balance */}

//           {/* Total Spent */}
//           <div className="expenses">
//             <span className="material-icons-sharp">stacked_line_chart</span>
//             <div className="middle">
//               <div className="left">
//                 <h3>Total Expense</h3>
//                 <h1>${dashboardData.total_expense.toFixed(2)}</h1>
//               </div>
//               <div className="progress">
//                 <svg>
//                   <circle cx="38" cy="38" r="36"></circle>
//                 </svg>
//                 <div className="number">
//                   <p>44%</p>
//                 </div>
//               </div>
//             </div>
//             <small className="text-muted">Last 24 hours</small>
//           </div>
//           {/* END OF Total Spent */}
//         </div>

//         <div className="history">
//           <h2>History</h2>
//           <div className="table-container">
//             <table>
//               <thead>
//                 <tr>
//                   <th>Sr No</th>
//                   <th>User Name</th>
//                   <th>Email</th>
//                   <th>Total Balance</th>
//                   <th>Total Expense</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {users.map((user, index) => (
//                   <tr key={user.id}>
//                     <td>{index + 1}.</td>
//                     <td>{user.first_name}</td>
//                     <td>{user.email}</td>
//                     <td>${user.total_balance}</td>
//                     <td>${user.total_expense}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

//
// import React, { useState, useEffect, useCallback } from "react";
// import { useNavigate } from "react-router-dom";

// export default function AdminHome() {
//   const [users, setUsers] = useState([]);
//   const [dashboardData, setDashboardData] = useState({
//     total_balance: 0,
//     total_expense: 0,
//   });
//   const [currentPage, setCurrentPage] = useState(1); // Track the current page
//   const [rowsPerPage] = useState(5); // Number of rows per page
//   const navigate = useNavigate();

//   const fetchUsers = useCallback(async () => {
//     try {
//       const accessToken = localStorage.getItem("access_token");

//       if (!accessToken) {
//         console.error("No access token found in local storage");
//         navigate("/"); // Redirect to home page if no token is found
//         return;
//       }

//       const response = await fetch(
//         "https://devapi.ivoz.ai/admin/users?page=1&per_page=20", // You might need to update this URL based on your pagination strategy
//         {
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         setUsers(data); // Store the user data in the state
//       } else {
//         console.error("Failed to fetch users");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   }, [navigate]);

//   const fetchDashboardAnalytics = useCallback(async () => {
//     try {
//       const accessToken = localStorage.getItem("access_token");

//       if (!accessToken) {
//         console.error("No access token found in local storage");
//         navigate("/"); // Redirect to home page if no token is found
//         return;
//       }

//       const response = await fetch(
//         "https://devapi.ivoz.ai/admin/dashboard-analytics",
//         {
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         setDashboardData({
//           total_balance: data.total_balance,
//           total_expense: data.total_expense,
//         });
//       } else {
//         console.error("Failed to fetch dashboard analytics");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   }, [navigate]);

//   useEffect(() => {
//     fetchUsers();
//     fetchDashboardAnalytics();
//   }, [fetchUsers, fetchDashboardAnalytics]);

//   // Calculate which rows to display based on the current page
//   const indexOfLastUser = currentPage * rowsPerPage;
//   const indexOfFirstUser = indexOfLastUser - rowsPerPage;
//   const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

//   // Change page
//   const paginate = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };
//   const handlePrevious = () => {
//     if (currentPage > 1) {
//       paginate(currentPage - 1);
//     }
//   };

//   const handleNext = () => {
//     if (indexOfLastUser < users.length) {
//       paginate(currentPage + 1);
//     }
//   };

//   return (
//     <>
//       <div className="main-inner">
//         <div className="upperMiddle">
//           <h1>Dashboard</h1>
//         </div>
//         <div className="insights">
//           {/* Account Balance */}
//           <div className="accountBal">
//             <span className="material-icons-sharp">analytics</span>
//             <div className="middle">
//               <div className="left">
//                 <h3>Account Balance</h3>
//                 <h1>${dashboardData.total_balance.toFixed(2)}</h1>
//               </div>
//               <div className="progress">
//                 <svg>
//                   <circle cx="38" cy="38" r="36"></circle>
//                 </svg>
//                 <div className="number">
//                   <p>81%</p>
//                 </div>
//               </div>
//             </div>
//             <small className="text-muted">Last 24 hours</small>
//           </div>
//           {/* END OF Account Balance */}

//           {/* On Hold Balance */}
//           <div className="onHoldBal">
//             <span className="material-icons-sharp">bar_chart</span>
//             <div className="middle">
//               <div className="left">
//                 <h3>On-hold Balance</h3>
//                 <h1>N/A</h1>
//               </div>
//               <div className="progress">
//                 <svg>
//                   <circle cx="38" cy="38" r="36"></circle>
//                 </svg>
//                 <div className="number">
//                   <p>41%</p>
//                 </div>
//               </div>
//             </div>
//             <small className="text-muted">Last 24 hours</small>
//           </div>
//           {/* END OF On Hold Balance */}

//           {/* Total Spent */}
//           <div className="expenses">
//             <span className="material-icons-sharp">stacked_line_chart</span>
//             <div className="middle">
//               <div className="left">
//                 <h3>Total Expense</h3>
//                 <h1>${dashboardData.total_expense.toFixed(2)}</h1>
//               </div>
//               <div className="progress">
//                 <svg>
//                   <circle cx="38" cy="38" r="36"></circle>
//                 </svg>
//                 <div className="number">
//                   <p>44%</p>
//                 </div>
//               </div>
//             </div>
//             <small className="text-muted">Last 24 hours</small>
//           </div>
//           {/* END OF Total Spent */}
//         </div>

//         <div className="history">
//           <h2>History</h2>
//           <div className="table-container">
//             <table>
//               <thead>
//                 <tr>
//                   <th>Sr No</th>
//                   <th>User Name</th>
//                   <th>Email</th>
//                   <th>Total Balance</th>
//                   <th>Total Expense</th>
//                 </tr>
//               </thead>
//               <tbody>
//               {currentUsers.map((user, index) => (
//     <tr key={user.id}>
//       <td>{(currentPage - 1) * rowsPerPage + index + 1}.</td> {/* Updated for proper serial number */}
//       <td>{user.first_name}</td>
//       <td>{user.email}</td>
//       <td>${user.total_balance}</td>
//       <td>${user.total_expense}</td>
//     </tr>
//   ))}
//               </tbody>
//             </table>
//           </div>
//         </div>

//         {/* Pagination Controls */}
//         <div className="pagination">
//   <button
//     onClick={handlePrevious}
//     disabled={currentPage === 1}
//   >
//     Previous
//   </button>
//   <span>Page {currentPage}</span>
//   <button
//     onClick={handleNext}
//     disabled={indexOfLastUser >= users.length}
//   >
//     Next
//   </button>
// </div>
//       </div>
//     </>
//   );
// }

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

export default function AdminHome() {
  const [users, setUsers] = useState([]);
  const [dashboardData, setDashboardData] = useState({
    total_balance: 0,
    total_expense: 0,
  });
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [rowsPerPage] = useState(5); // Number of rows per page
  const navigate = useNavigate();

  const fetchUsers = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("access_token");

      if (!accessToken) {
        console.error("No access token found in local storage");
        navigate("/"); // Redirect to home page if no token is found
        return;
      }

      const response = await fetch(
        "https://devapi.ivoz.ai/admin/users?page=1&per_page=20", // You might need to update this URL based on your pagination strategy
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUsers(data); // Store the user data in the state
      } else {
        console.error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [navigate]);

  const fetchDashboardAnalytics = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem("access_token");

      if (!accessToken) {
        console.error("No access token found in local storage");
        navigate("/"); // Redirect to home page if no token is found
        return;
      }

      const response = await fetch(
        "https://devapi.ivoz.ai/admin/dashboard-analytics",
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDashboardData({
          total_balance: data.total_balance,
          total_expense: data.total_expense,
        });
      } else {
        console.error("Failed to fetch dashboard analytics");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [navigate]);

  useEffect(() => {
    fetchUsers();
    fetchDashboardAnalytics();
  }, [fetchUsers, fetchDashboardAnalytics]);

  // Calculate which rows to display based on the current page
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (indexOfLastUser < users.length) {
      paginate(currentPage + 1);
    }
  };

  const downloadCSV = () => {
    const headers = [
      "Sr No",
      "User Name",
      "Email",
      "Total Balance",
      "Total Expense",
    ];
    const rows = users.map((user, index) => [
      index + 1,
      user.first_name,
      user.email,
      `$${user.total_balance}`,
      `$${user.total_expense}`,
    ]);

    // Create CSV content
    const csvContent = [
      headers.join(","), // Header row
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    // Create a link to trigger download
    const link = document.createElement("a");
    link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
    link.target = "_blank";
    link.download = "users_data.csv";
    link.click();
  };

  return (
    <>
      <div className="main-inner">
        <div className="upperMiddle">
          <h1>Dashboard</h1>
        </div>
        <div className="insights">
          {/* Account Balance */}
          <div className="accountBal">
            <span className="material-icons-sharp">analytics</span>
            <div className="middle">
              <div className="left">
                <h3>Account Balance</h3>
                <h1>${dashboardData.total_balance.toFixed(2)}</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>81%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Last 24 hours</small>
          </div>
          {/* END OF Account Balance */}

          {/* On Hold Balance */}
          <div className="onHoldBal">
            <span className="material-icons-sharp">bar_chart</span>
            <div className="middle">
              <div className="left">
                <h3>On-hold Balance</h3>
                <h1>N/A</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>41%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Last 24 hours</small>
          </div>
          {/* END OF On Hold Balance */}

          {/* Total Spent */}
          <div className="expenses">
            <span className="material-icons-sharp">stacked_line_chart</span>
            <div className="middle">
              <div className="left">
                <h3>Total Expense</h3>
                <h1>${dashboardData.total_expense.toFixed(2)}</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>44%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Last 24 hours</small>
          </div>
          {/* END OF Total Spent */}
        </div>

        <div className="history">
          
          <div className="table-container">
          
            <div className="download-csv-btn">
            <h2>History</h2>
              <button onClick={downloadCSV}>Download CSV</button>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>Total Balance</th>
                  <th>Total Expense</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user, index) => (
                  <tr key={user.id}>
                    <td>{(currentPage - 1) * rowsPerPage + index + 1}.</td>{" "}
                    {/* Updated for proper serial number */}
                    <td>{user.first_name}</td>
                    <td>{user.email}</td>
                    <td>${user.total_balance}</td>
                    <td>${user.total_expense}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination Controls */}
        <div className="pagination">
          <button onClick={handlePrevious} disabled={currentPage === 1}>
            Previous
          </button>
          <span>Page {currentPage}</span>
          <button
            onClick={handleNext}
            disabled={indexOfLastUser >= users.length}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
