// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
// import "./auth.css";
// import { FaUser, FaLock, FaEnvelope } from "react-icons/fa";

// const SignIn = () => {
//   const [isSignUpMode, setIsSignUpMode] = useState(false);
//   const [signupForm, setSignupForm] = useState({
//     name: "",
//     email: "",
//     password: "",
//   });
//   const [signinForm, setSigninForm] = useState({
//     email: "",
//     password: "",
//   });

//   const navigate = useNavigate(); // Initialize useNavigate hook

//   const handleSignUpClick = () => {
//     setIsSignUpMode(true);
//   };

//   const handleSignInClick = () => {
//     setIsSignUpMode(false);
//   };

//   const handleSignupChange = (e) => {
//     setSignupForm({ ...signupForm, [e.target.name]: e.target.value });
//   };

//   const handleSigninChange = (e) => {
//     setSigninForm({ ...signinForm, [e.target.name]: e.target.value });
//   };

//   const handleSignupSubmit = async (e) => {
//     e.preventDefault();
//     const response = await fetch("https://devapi.ivoz.ai/signup/", {
//       method: "POST",
//       headers: {
//         accept: "application/json",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(signupForm),
//     });

//     if (response.ok) {
//       const data = await response.json();
//       console.log("Signup successful:", data);
//       // Handle successful signup (e.g., display a message, redirect, etc.)
//     } else {
//       console.error("Signup failed:", response.statusText);
//       // Handle signup failure (e.g., display an error message)
//     }
//   };

//   const handleSigninSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch("https://devapi.ivoz.ai/auth/login/    ", {
//         method: "POST",
//         headers: {
//           accept: "application/json",
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(signinForm),
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log("Signin successful:", data);

//         // Store tokens and role in local storage

//         localStorage.setItem("access_token", data.access_token);
//         localStorage.setItem("refresh_token", data.refresh_token);
//         localStorage.setItem("role", JSON.stringify(data.role));
//         if (data.role.includes("admin")) {
//           navigate("/adminBoard");
//         } else if (
//           data.role.includes("standard_user") &&
//           !data.role.includes("admin")
//         ) {
//           navigate("/userboard");
//         } else {
//           alert("Invalid role. Please contact support.");
//         }
//       } else if (response.status === 404) {
//         const errorData = await response.json();
//         if (errorData.detail === "User not found") {
//           alert("Incorrect information. Please check your email and password.");
//         } else {
//           alert("Signin failed. Please try again.");
//         }
//       } else if (response.status === 401) {
//         const errorData = await response.json();
//         if (errorData.detail === "Incorrect password") {
//           alert("Incorrect password. Please try again.");
//         } else {
//           alert("Signin failed. Please try again.");
//         }
//       } else {
//         console.error("Signin failed:", response.statusText);
//         alert("An unexpected error occurred. Please try again later.");
//       }
//     } catch (error) {
//       console.error("Signin error:", error);
//       alert(
//         "A network error occurred. Please check your connection and try again."
//       );
//     }
//   };

//   return (
//     <div className={`container ${isSignUpMode ? "sign-up-mode" : ""}`}>
//       <div className="forms-container">
//         <div className="signin-signup">
//           <form
//             action="#"
//             className={`sign-in-form ${isSignUpMode ? "hidden" : ""}`}
//             onSubmit={handleSigninSubmit}
//           >
//             <h2 className="title">Sign in</h2>
//             <div className="input-field">
//               <i>
//                 <FaUser />
//               </i>
//               <input
//                 type="text"
//                 placeholder="Email"
//                 name="email"
//                 value={signinForm.email}
//                 onChange={handleSigninChange}
//               />
//             </div>
//             <div className="input-field">
//               <i>
//                 <FaLock />
//               </i>
//               <input
//                 type="password"
//                 placeholder="Password"
//                 name="password"
//                 value={signinForm.password}
//                 onChange={handleSigninChange}
//               />
//             </div>
//             <input type="submit" value="Login" className="btn solid" />

//             <div className="forgot-pswd">
//               <Link to="#">Forgot Password</Link>
//             </div>
//           </form>
//           {/* <form
//             action="#"
//             className={`sign-up-form ${!isSignUpMode ? "hidden" : ""}`}
//             onSubmit={handleSignupSubmit}
//           >
//             <h2 className="title">Sign up</h2>
//             <div className="input-field">
//               <i>
//                 <FaUser />
//               </i>
//               <input
//                 type="text"
//                 placeholder="Username"
//                 name="name"
//                 value={signupForm.name}
//                 onChange={handleSignupChange}
//               />
//             </div>
//             <div className="input-field">
//               <i>
//                 <FaEnvelope />
//               </i>
//               <input
//                 type="email"
//                 placeholder="Email"
//                 name="email"
//                 value={signupForm.email}
//                 onChange={handleSignupChange}
//               />
//             </div>
//             <div className="input-field">
//               <i>
//                 <FaLock />
//               </i>
//               <input
//                 type="password"
//                 placeholder="Password"
//                 name="password"
//                 value={signupForm.password}
//                 onChange={handleSignupChange}
//               />
//             </div>
//             <input type="submit" className="btn" value="Sign up" />
//           </form> */}
//         </div>
//       </div>

//       <div className="panels-container">
//         <div className="panel left-panel">
//           <div className="content">
//             <h3>New here?</h3>
//             <p>Join us today and unlock exclusive features!</p>
//             <button className="btn transparent" onClick={handleSignUpClick}>
//               Sign up
//             </button>
//           </div>
//           <img
//             src="https://i.ibb.co/6HXL6q1/Privacy-policy-rafiki.png"
//             className="image"
//             alt=""
//           />
//         </div>
//         <div className="panel right-panel">
//           <div className="content">
//             <h3>One of us?</h3>
//             <p>
//               Welcome! Sign in to continue or create an account to get started
//               with us.
//             </p>
//             <button className="btn transparent" onClick={handleSignInClick}>
//               Sign in
//             </button>
//           </div>
//           <img
//             src="https://i.ibb.co/nP8H853/Mobile-login-rafiki.png"
//             className="image"
//             alt=""
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SignIn;

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import "./auth.css";
// import { Link } from "react-router-dom";
// import { FaUser, FaLock, FaEnvelope } from "react-icons/fa";

// const SignIn = () => {
//   const [isSignUpMode, setIsSignUpMode] = useState(false);
//   const [isForgotPasswordMode, setIsForgotPasswordMode] = useState(false);
//   const [signinForm, setSigninForm] = useState({
//     email: "",
//     password: "",
//   });
//   const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const navigate = useNavigate();

//   const handleSignUpClick = () => {
//     setIsSignUpMode(true);
//     setIsForgotPasswordMode(false);
//     setErrorMessage("");
//   };
//   const handleSignInClick = () => {
//     setIsSignUpMode(false);
//     setIsForgotPasswordMode(false);
//     setErrorMessage("");
//   };
//   const handleForgotPasswordClick = () => {
//     setIsForgotPasswordMode(true);
//     setErrorMessage("");
//   };
//   const handleSigninChange = (e) => {
//     setSigninForm({ ...signinForm, [e.target.name]: e.target.value });
//     setErrorMessage("");
//   };
//   const handleForgotPasswordChange = (e) => {
//     setForgotPasswordEmail(e.target.value);
//   };

//   const handleSigninSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch("https://devapi.ivoz.ai/auth/login/", {
//         method: "POST",
//         headers: {
//           accept: "application/json",
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(signinForm),
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log("Signin successful:", data);

//         // Store tokens and role in local storage
//         localStorage.setItem("access_token", data.access_token);
//         localStorage.setItem("refresh_token", data.refresh_token);
//         localStorage.setItem("role", JSON.stringify(data.role));
//         if (data.role.includes("admin")) {
//           navigate("/adminBoard");
//         } else if (
//           data.role.includes("standard_user") &&
//           !data.role.includes("admin")
//         ) {
//           navigate("/userboard");
//         } else {
//           setErrorMessage("Invalid role. Please contact support.");
//         }
//       } else if (response.status === 404) {
//         const errorData = await response.json();
//         if (errorData.detail === "User not found") {
//           setErrorMessage(
//             "Incorrect information. Please check your email and password."
//           );
//         } else {
//           setErrorMessage("Signin failed. Please try again.");
//         }
//       } else if (response.status === 401) {
//         const errorData = await response.json();
//         if (errorData.detail === "Incorrect password") {
//           setErrorMessage("Incorrect password. Please try again.");
//         } else {
//           setErrorMessage("Signin failed. Please try again.");
//         }
//       } else {
//         console.error("Signin failed:", response.statusText);
//         setErrorMessage("An unexpected error occurred. Please try again later.");
//       }
//     } catch (error) {
//       console.error("Signin error:", error);
//       setErrorMessage(
//         "A network error occurred. Please check your connection and try again."
//       );
//     }
//   };

//   const handleForgotPasswordSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const email = encodeURIComponent(forgotPasswordEmail); // Encode the email to ensure it works in the query string
//       const response = await fetch(
//         `https://devapi.ivoz.ai/auth/forget-password?email=${email}`,
//         {
//           method: "GET",
//           headers: {
//             accept: "application/json",
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         if (data.status) {
//           alert(data.message); // Display the success message
//         } else {
//           alert("Failed to send password reset email. Please try again.");
//         }
//       } else {
//         alert("Failed to send password reset email. Please check the email and try again.");
//       }
//     } catch (error) {
//       console.error("Forgot password error:", error);
//       alert("An unexpected error occurred. Please try again later.");
//     }
//   };

//   return (
//     <div className={`container ${isSignUpMode ? "sign-up-mode" : ""}`}>
//       <div className="forms-container">
//         <div className="signin-signup">
//           {/* Sign-In Form */}
//           {!isForgotPasswordMode && (
//             <form
//               action="#"
//               className={`sign-in-form ${isSignUpMode ? "hidden" : ""}`}
//               onSubmit={handleSigninSubmit}
//             >
//               <h2 className="title">Sign in</h2>
//               <div className="input-field">
//                 <i>
//                   <FaUser />
//                 </i>
//                 <input
//                   type="text"
//                   placeholder="Email"
//                   name="email"
//                   value={signinForm.email}
//                   onChange={handleSigninChange}
//                 />
//               </div>
//               <div className="input-field">
//                 <i>
//                   <FaLock />
//                 </i>
//                 <input
//                   type="password"
//                   placeholder="Password"
//                   name="password"
//                   value={signinForm.password}
//                   onChange={handleSigninChange}
//                 />
//               </div>
//               {/* Display Error Message */}
//               {errorMessage && (
//                 <div className="error-message">
//                   {errorMessage}
//                 </div>
//               )}
//               <input type="submit" value="Login" className="btn solid" />
//               <div className="forgot-pswd">
//                 <button
//                   type="button"
//                   onClick={handleForgotPasswordClick}
//                   className="btn-link"
//                 >
//                   <Link>Forgot Password</Link>
//                 </button>
//               </div>
//             </form>
//           )}

//           {/* Forgot Password Form */}
//           {isForgotPasswordMode && (
//             <form
//               action="#"
//               className="forgot-password-form"
//               onSubmit={handleForgotPasswordSubmit}
//             >
//               <h2 className="title">Forgot Password</h2>
//               <div className="input-field">
//                 <i>
//                   <FaEnvelope />
//                 </i>
//                 <input
//                   type="email"
//                   placeholder="Enter your email"
//                   value={forgotPasswordEmail}
//                   onChange={handleForgotPasswordChange}
//                 />
//               </div>
//               <div className="fp-buttons">
//                 <button
//                   type="button"
//                   className="btn solid"
//                   onClick={handleSignInClick}
//                 >
//                   Previous
//                 </button>
//                 <input type="submit" value="Submit" className="btn solid" />
//               </div>
//             </form>
//           )}
//         </div>
//       </div>

//       <div className="panels-container">
//         <div className="panel left-panel">
//           <div className="content">
//             <h3>New here?</h3>
//             <p>Join us today and unlock exclusive features!</p>
//             <button className="btn transparent" onClick={handleSignUpClick}>
//               Sign up
//             </button>
//           </div>
//           <img
//             src="https://i.ibb.co/6HXL6q1/Privacy-policy-rafiki.png"
//             className="image"
//             alt=""
//           />
//         </div>
//         <div className="panel right-panel">
//           <div className="content">
//             <h3>One of us?</h3>
//             <p>
//               Welcome! Sign in to continue or create an account to get started
//               with us.
//             </p>
//             <button className="btn transparent" onClick={handleSignInClick}>
//               Sign in
//             </button>
//           </div>
//           <img
//             src="https://i.ibb.co/nP8H853/Mobile-login-rafiki.png"
//             className="image"
//             alt=""
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SignIn;

import React, { useState } from "react";
import "./auth.css";
import { FaEnvelope } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

const Password = () => {
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate(); // Initialize navigate

  const handleForgotPasswordChange = (e) => {
    setForgotPasswordEmail(e.target.value);
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const email = encodeURIComponent(forgotPasswordEmail); // Encode the email to ensure it works in the query string
      const response = await fetch(
        `https://devapi.ivoz.ai/auth/forget-password?email=${email}`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.status) {
          setSuccessMessage(data.message); // Display the success message
        } else {
          setErrorMessage(
            "Failed to send password reset email. Please try again."
          );
        }
      } else {
        setErrorMessage(
          "Failed to send password reset email. Please check the email and try again."
        );
      }
    } catch (error) {
      console.error("Forgot password error:", error);
      setErrorMessage("An unexpected error occurred. Please try again later.");
    }
  };

  // Handle navigation to the previous page
  const handlePreviousClick = () => {
    navigate("/"); // Navigate to the root path
  };

  return (
    <div className="container">
      <div className="forms-container">
        <div className="signin-signup">
          <div className="forgot-password-form">
            <form action="#" onSubmit={handleForgotPasswordSubmit}>
              <h2 className="title">Forgot Password</h2>
              <div className="input-field">
                <i>
                  <FaEnvelope />
                </i>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={forgotPasswordEmail}
                  onChange={handleForgotPasswordChange}
                />
              </div>
              {/* Display Success or Error Messages */}
              {successMessage && (
                <div className="success-message">{successMessage}</div>
              )}
              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              <div className="fp-buttons">
                <button onClick={handlePreviousClick} className="btn outline">
                  Previous
                </button>
                <input type="submit" value="Submit" className="btn solid" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3>Forgot your password?</h3>
            <p>
              Enter your registered email, and we will help you reset your
              password.
            </p>
          </div>
          <img
            src="https://i.ibb.co/6HXL6q1/Privacy-policy-rafiki.png"
            className="image"
            alt="Forgot Password Illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default Password;
