// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom"; // Import useNavigate
// import "./createuser.css";

// const CreateUser = () => {
//   const navigate = useNavigate(); // Initialize navigate
//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//     first_name: "",
//     last_name: "",
//     phone: "",
//     add_1: "",
//     add_2: "",
//     city: "",
//     state: "",
//     zipcode: "",
//     initial_balance: 0,
//     is_active: true,
//     verified: true,
//   });
//   const [error, setError] = useState("");

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const accessToken = localStorage.getItem("access_token");
//       const response = await fetch("https://devapi.ivoz.ai/admin/create-user", {
//         method: "POST",
//         headers: {
//           accept: "application/json",
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${accessToken}`,
//         },
//         body: JSON.stringify(formData),
//       });
  
//       if (response.ok) {
//         await response.json(); // Removed assignment of `data`
//         alert("User created successfully!");
//         window.location.reload();
//       } else {
//         setError("Failed to create user. Please check the input.");
//       }
//     } catch (err) {
//       setError("An error occurred: " + err.message);
//     }
//   };

//   const handlePrevious = () => {
//     navigate("/adminBoard/Profile"); // Navigate to the desired path
//   };

//   return (
//     <div className="create-user-container">
//       <h2>Create New User</h2>
//       <form onSubmit={handleSubmit} className="create-user-form">
//         <div className="userForm-inner">
//           <input
//             type="email"
//             name="email"
//             placeholder="Email"
//             value={formData.email}
//             onChange={handleChange}
//             required
//           />
//           <input
//             type="password"
//             name="password"
//             placeholder="Password"
//             value={formData.password}
//             onChange={handleChange}
//             required
//           />
//           <input
//             type="text"
//             name="first_name"
//             placeholder="First Name"
//             value={formData.first_name}
//             onChange={handleChange}
//             required
//           />
//           <input
//             type="text"
//             name="last_name"
//             placeholder="Last Name"
//             value={formData.last_name}
//             onChange={handleChange}
//             required
//           />
//           <input
//             type="text"
//             name="phone"
//             placeholder="Phone"
//             value={formData.phone}
//             onChange={handleChange}
//             required
//           />
//           <input
//             type="text"
//             name="add_1"
//             placeholder="Address Line 1"
//             value={formData.add_1}
//             onChange={handleChange}
//           />
//           <input
//             type="text"
//             name="add_2"
//             placeholder="Address Line 2"
//             value={formData.add_2}
//             onChange={handleChange}
//           />
//           <input
//             type="text"
//             name="city"
//             placeholder="City"
//             value={formData.city}
//             onChange={handleChange}
//             required
//           />
//           <input
//             type="text"
//             name="state"
//             placeholder="State"
//             value={formData.state}
//             onChange={handleChange}
//             required
//           />
//           <input
//             type="text"
//             name="zipcode"
//             placeholder="Zip Code"
//             value={formData.zipcode}
//             onChange={handleChange}
//             required
//           />
//           <input
//             type="number"
//             name="initial_balance"
//             placeholder="Initial Balance"
//             value={formData.initial_balance}
//             onChange={handleChange}
//           />
//         </div>
//         <div className="user-buttons">
//           <button
//             className="createUserButton"
//             type="button" // Ensure this button doesn't submit the form
//             onClick={handlePrevious}
//           >
//             Cancel
//           </button>
//           <button className="createUserButton" type="submit">
//             Create User
//           </button>
//         </div>
//       </form>
//       {error && <p className="error">{error}</p>}
//     </div>
//   );
// };

// export default CreateUser;


import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./createuser.css";

const CreateUser = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    phone: "",
    add_1: "",
    add_2: "",
    city: "",
    state: "",
    zipcode: "",
    initial_balance: 0,
    is_active: true,
    verified: true,
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await fetch("https://devapi.ivoz.ai/admin/create-user", {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        alert("User created successfully!");
        window.location.reload(); // Refresh the page after success
      } else {
        if (response.status === 400 && responseData.detail) {
          // Handle 400 errors with a single detail message
          alert(responseData.detail);
        } else if (response.status === 422 && Array.isArray(responseData.detail)) {
          // Handle 422 errors with validation messages
          const validationMessage = responseData.detail
            .map((error) => error.msg) // Extract `msg` from each error object
            .join("\n"); // Combine messages if multiple errors exist
          alert(validationMessage);
        } else {
          // Fallback for other error formats
          alert(responseData.message || "An error occurred. Please try again.");
        }
      }
    } catch (err) {
      // Handle unexpected errors
      alert("An error occurred: " + err.message);
    }
  };
  
  
  

  const handlePrevious = () => {
    navigate("/adminBoard/Profile");
  };

  return (
    <div className="create-user-container">
      <h2>Create New User</h2>
      <form onSubmit={handleSubmit} className="create-user-form">
        <div className="userForm-inner">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="first_name"
            placeholder="First Name"
            value={formData.first_name}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            value={formData.last_name}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="add_1"
            placeholder="Address Line 1"
            value={formData.add_1}
            onChange={handleChange}
          />
          <input
            type="text"
            name="add_2"
            placeholder="Address Line 2"
            value={formData.add_2}
            onChange={handleChange}
          />
          <input
            type="text"
            name="city"
            placeholder="City"
            value={formData.city}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="state"
            placeholder="State"
            value={formData.state}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="zipcode"
            placeholder="Zip Code"
            value={formData.zipcode}
            onChange={handleChange}
            required
          />
          <input
            type="number"
            name="initial_balance"
            placeholder="Initial Balance"
            value={formData.initial_balance}
            onChange={handleChange}
          />
        </div>
        <div className="user-buttons">
          <button
            className="createUserButton"
            type="button"
            onClick={handlePrevious}
          >
            Cancel
          </button>
          <button className="createUserButton" type="submit">
            Create User
          </button>
        </div>
      </form>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default CreateUser;
