import React, { useState, useEffect } from "react";
import "./admin.css";
import "./adminUserProfiles.css";
import { useNavigate } from "react-router-dom";

const AdminProfile = () => {
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [creditAmount, setCreditAmount] = useState("");
  const [error, setError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [campaignData, setCampaignData] = useState(null);
  const [viewUserData, setViewUserData] = useState(false);
  const usersPerPage = 8;
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        setError("No access token found in local storage");
        return;
      }

      const response = await fetch(
        "https://devapi.ivoz.ai/admin/users?page=1&per_page=15",
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUsers(data);
        setFilteredUsers(data);
      }
       else {
        setError("Failed to fetch users");
      }
    } catch (error) {
      setError("Error fetching users: " + error.message);
    }
  };

  useEffect(() => {
    if (query) {
      setFilteredUsers(
        users.filter((user) => user.email.toLowerCase().includes(query.toLowerCase()))
      );
      setCurrentPage(1);
    } else {
      setFilteredUsers(users);
    }
  }, [query, users]);

  const handleCreditSubmit = async () => {
    if (!creditAmount) {
      setError("Please enter an amount to credit.");
      return;
    }

    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await fetch(
        "https://devapi.ivoz.ai/admin/modify-balance",
        {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            user: selectedUser.email,
            log: "Credited by admin",
            amount: parseFloat(creditAmount),
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        alert(data.message);
        setShowPopup(false);
        setCreditAmount("");
      } else {
        setError("Failed to adjust amount");
      }
    } catch (error) {
      setError("Error submitting credit amount: " + error.message);
    }
  };

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const currentUsers = filteredUsers.slice(
    (currentPage - 1) * usersPerPage,
    currentPage * usersPerPage
  );

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const fetchCampaignData = async (email) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        setError("No access token found in local storage");
        return;
      }

      const response = await fetch(
        `https://devapi.ivoz.ai/admin/campaigns/?email=${email}&page=1&per_page=10`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCampaignData(data);
      } else {
        setError("Failed to fetch campaign data");
      }
    } catch (error) {
      setError("Error fetching campaign data: " + error.message);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setViewUserData(true);
    fetchCampaignData(user.email);
  };

  // Toggle Campaign Status (Start/Stop)
  const handleStatusToggle = async (campaignId, currentStatus) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        setError("No access token found in local storage");
        return;
      }
  
      const newStatus = currentStatus === "started" ? "stopped" : "started";
      const response = await fetch(
        `https://devapi.ivoz.ai/admin/campaigns/update-status/${campaignId}?status=${newStatus}`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
  
      if (response.ok) {
        const data = await response.json();
        alert(data.message);
  
        // Update campaign data after status change
        const updatedCampaignData = campaignData.map((campaign) =>
          campaign.id === campaignId
            ? { ...campaign, status: newStatus }
            : campaign
        );
        setCampaignData(updatedCampaignData);
      } else {
        setError("Failed to update campaign status");
      }
    } catch (error) {
      setError("Error updating campaign status: " + error.message);
    }
  };
  

  return (
    <div className="admin-container">
      {!viewUserData ? (
        <>
          <div className="topBar">
            <div className="top-bar-search">
              <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Enter Email"
                className="searchInput"
              />
            </div>
            <div className="top-bar-button">
              <button
                className="createUserButton"
                onClick={() => navigate("/adminBoard/editUser")}
              >
                Edit User
              </button>
              <button
                className="createUserButton"
                onClick={() => navigate("/adminBoard/createUser")}
              >
                Create User
              </button>
            </div>
          </div>

          <div className="history">
            <h2>Users</h2>
            <p className="userprofileTable"> On click on username you can see running campaigns of the user.</p>
            <table>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>Credit Amount</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user, index) => (
                  <tr key={user.id} onClick={() => handleUserClick(user)}>
                    <td>{(currentPage - 1) * usersPerPage + index + 1}</td>
                    <td>{user.first_name}</td>
                    <td>{user.email}</td>
                    <td>
                      <button
                        className="creditButton"
                        onClick={() => {
                          setSelectedUser(user);
                          setShowPopup(true);
                        }}
                      >
                        Credit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="createUserButton"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="createUserButton"
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <>
          <h2>Campaign Data for {selectedUser?.email}</h2>
          <table>
            <thead>
              <tr>
                <th>Campaign ID</th>
                <th>Campaign Name</th>
                <th>Created At</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
  {campaignData?.map((campaign) => (
    <tr key={campaign.id}>
      <td>{campaign.id}</td>
      <td>{campaign.name}</td>
      <td>{campaign.created_on}</td>
      <td>
      <span
          className={`status-label ${
            campaign.status === "started"
              ? "success"
              : campaign.status === "stopped"
              ? "danger"
              : "warning"
          }`}
        >
          {campaign.status}
        </span>
      </td>
      <td>
        <button
          className={`action-button ${
            campaign.status === "started" ? "danger" : "success"
          }`}
          onClick={() => handleStatusToggle(campaign.id, campaign.status)}
        >
          {campaign.status === "started" ? "Stop" : "Start"}
        </button>
      </td>
    </tr>
  ))}
</tbody>


          </table>
          <button className="backtouser" onClick={() => setViewUserData(false)}>
            Back to Users
          </button>
        </>
      )}

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Credit Amount for {selectedUser?.name}</h3>
            <input
              type="number"
              value={creditAmount}
              onChange={(e) => setCreditAmount(e.target.value)}
              placeholder="Enter amount"
            />
            <br />
            <button onClick={handleCreditSubmit}>Submit</button>
            <button onClick={() => setShowPopup(false)}>Cancel</button>
          </div>
        </div>
      )}

      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default AdminProfile;
