import React from 'react';
import './ViewKnowledgebase.css';

const sampleKnowledgebase = [
  { id: '1', date: '2023-06-01', type: 'pdf', status: 'Trained' },
  { id: '2', date: '2023-06-02', type: 'url', status: 'Pending' },
  { id: '3', date: '2023-06-03', type: 'text', status: 'Trained' },
];

function ViewKnowledgebase({ agentName, onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">Knowledgebase for {agentName}</h2>
        <table className="knowledgebase-table">
          <thead>
            <tr className="table-header">
              <th>Date</th>
              <th>Type</th>
              <th>Status</th>
              <th className="action-column">Action</th>
            </tr>
          </thead>
          <tbody>
            {sampleKnowledgebase.map((item) => (
              <tr key={item.id} className="table-row">
                <td>{item.date}</td>
                <td>{item.type}</td>
                <td>{item.status}</td>
                <td className="action-column">
                  <button className="download-btn">Download</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="modal-footer">
          <button onClick={onClose} className="close-btn">
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default ViewKnowledgebase;
