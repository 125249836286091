import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import './mainboard.css';

export default function BotDetails() {
  const { botId } = useParams(); // Extract the bot ID from the URL
  const [callLogs, setCallLogs] = useState([]); // State to store call logs
  const navigate = useNavigate(); // Use navigate to navigate the user

  useEffect(() => {
    const fetchCallLogs = async () => {
      const token = localStorage.getItem("access_token"); // Get the token from local storage
      if (!token) {
        navigate("/"); // Navigate to the home page if no token is found
        return;
      }

      try {
        const response = await fetch(
          `https://devapi.ivoz.ai/users/call-logs?bot_id=${botId}&page=1&per_page=10`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`, // Include the bearer token
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCallLogs(data); // Set the call logs in state
        } else if (response.status === 401) {
          console.error("Token expired or invalid.");
          localStorage.removeItem("access_token"); // Remove the invalid token
          navigate("/"); // Navigate to the home page if token is invalid or expired
        } else {
          console.error("Failed to fetch call logs");
        }
      } catch (error) {
        console.error("Error fetching call logs:", error);
      }
    };

    fetchCallLogs();
  }, [botId, navigate]);

  return (
    <div>
      <h2>Call Logs for Bot ID: {botId}</h2>
      {callLogs.length > 0 ? (
        <div style={{ overflowX: 'auto' }}> {/* Container with horizontal scroll only */}
          <table style={{ minWidth: '800px' }}> {/* Ensure table has minimum width for horizontal scroll */}
            <thead>
              <tr>
                <th>Flow Phone</th>
                <th>Customer Number</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Duration (s)</th>
                <th>Pulses Consumed</th>
              </tr>
            </thead>
            <tbody>
              {callLogs.map((log, index) => (
                <tr key={index}>
                  <td>{log.bot_phone}</td>
                  <td>{log.from_number ? log.from_number : "<private_number>"}</td> {/* Check for empty or anonymous number */}
                  <td>{new Date(log.start_time).toLocaleString()}</td>
                  <td>{new Date(log.end_time).toLocaleString()}</td>
                  <td>{log.duration}</td>
                  <td>{log.unit_consumed}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No call logs available for this bot.</p>
      )}
    </div>
  );
}
