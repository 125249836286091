import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate from react-router-dom
import ChatWindow from './chat-window';
import UpdateKnowledgebase from './update-knowledgebase';
import ViewKnowledgebase from './view-knowledgebase';
import './AgentListing.css';

const mockAgents = [
  { id: '1', name: 'Agent 1', type: 'QNA', createdOn: '2023-06-01' },
  { id: '2', name: 'Agent 2', type: 'Actionable', createdOn: '2023-06-02' },
];

export default function AgentListing() {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [showUpdateKnowledgebase, setShowUpdateKnowledgebase] = useState(false);
  const [selectedAgentForUpdate, setSelectedAgentForUpdate] = useState(null);
  const [showViewKnowledgebase, setShowViewKnowledgebase] = useState(false);
  const [selectedAgentForView, setSelectedAgentForView] = useState(null);

  const navigate = useNavigate();  // Initialize useNavigate hook for navigation

  const handleToast = (message) => {
    alert(message); // Simple toast replacement
  };

  const handleAddAgentClick = () => {
    navigate('/userboard/addAgent'); // Navigate to the Add Agent page
  };

  return (
    <div className="agent-listing">
      <div className="header">
        <h2>Agent Listing</h2>
        <button className="add-agent-btn" onClick={handleAddAgentClick}>
          Add Agent
        </button>
      </div>
      <div className="table-container">
        <table className="agent-table">
          <thead>
            <tr>
              <th>Agent Name</th>
              <th>Agent Type</th>
              <th>Created On</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {mockAgents.map((agent) => (
              <tr key={agent.id}>
                <td className="agent-name">{agent.name}</td>
                <td>{agent.type}</td>
                <td>{agent.createdOn}</td>
                <td className="actions">
                  <button
                    className={`action-btn ${agent.type === 'Actionable' ? 'disabled' : ''}`}
                    onClick={() => setSelectedAgent(agent)}
                    disabled={agent.type === 'Actionable'}
                  >
                    Sandbox Test
                  </button>
                  <button
                    className={`action-btn ${agent.type === 'Actionable' ? 'disabled' : ''}`}
                    onClick={() => {
                      setSelectedAgentForUpdate(agent);
                      setShowUpdateKnowledgebase(true);
                    }}
                    disabled={agent.type === 'Actionable'}
                  >
                    Update Knowledgebase
                  </button>
                  <button
                    className={`action-btn ${agent.type === 'Actionable' ? 'disabled' : ''}`}
                    onClick={() => {
                      setSelectedAgentForView(agent);
                      setShowViewKnowledgebase(true);
                    }}
                    disabled={agent.type === 'Actionable'}
                  >
                    View Knowledgebase
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showUpdateKnowledgebase && selectedAgentForUpdate && (
        <UpdateKnowledgebase
          agentName={selectedAgentForUpdate.name}
          onClose={() => setShowUpdateKnowledgebase(false)}
          onSubmit={() => {
            setShowUpdateKnowledgebase(false);
            handleToast('New Training Data is being Added To Your Agent');
          }}
        />
      )}
      {showViewKnowledgebase && selectedAgentForView && (
        <ViewKnowledgebase
          agentName={selectedAgentForView.name}
          onClose={() => setShowViewKnowledgebase(false)}
        />
      )}
      {selectedAgent && (
        <ChatWindow
          agentName={selectedAgent.name}
          onClose={() => setSelectedAgent(null)}
        />
      )}
    </div>
  );
}
