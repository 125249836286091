import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./mainboard.css";

export default function Home() {
  const randomNumber = Math.floor(Math.random() * (100 - 50 + 1)) + 50;
  const [accountBal, setAccountBal] = useState("");
  const [totalExpense, setTotalExpense] = useState(0); // State for total expense
  const [bots, setBots] = useState([]); // State to store bots data
  const navigate = useNavigate(); // Initialize navigate function

  useEffect(() => {
    const fetchAccountDetails = async () => {
      const token = localStorage.getItem("access_token");

      if (!token) {
        navigate("/"); // Redirect to home page if no token
        return;
      }

      try {
        const response = await fetch("https://devapi.ivoz.ai/users/profile", {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAccountBal(data.balance);
          setTotalExpense(data.total_expense); // Set total expense
          setBots(data.bots || []); // Ensure bots data is an array
        } else {
          // Handle unauthorized error
          if (response.status === 401) {
            console.error("Unauthorized. Invalid or expired token.");
            navigate("/"); // Redirect to home page if token is invalid
          } else {
            console.error("Failed to fetch user data");
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        navigate("/"); // Redirect to home page in case of error
      }
    };

    fetchAccountDetails();
  }, [navigate]);

  const downloadLog = async (botId) => {
    const token = localStorage.getItem("access_token");

    if (!token) {
      navigate("/"); // Redirect to home page if no token
      return;
    }

    let page = 1;
    const perPage = 10; // The number of results per page
    let hasMoreData = true;

    try {
      while (hasMoreData) {
        const response = await fetch(
          `https://devapi.ivoz.ai/users/call-logs?bot_id=${botId}&page=${page}&per_page=${perPage}`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();

          if (data.length > 0) {
            // Convert the current chunk to CSV and download it
            const csv = convertToCSV(data);
            downloadCSV(csv, `call_logs_part_${page}.csv`);

            // Move to the next page
            page++;
          } else {
            // No more data available
            hasMoreData = false;
          }
        } else {
          console.error("Failed to fetch call logs");
          hasMoreData = false;
        }
      }
    } catch (error) {
      console.error("Error fetching call logs:", error);
    }
  };

  // Convert JSON data to CSV
  const convertToCSV = (data) => {
    const headers = [
      "call_id",
      "agent_phone",
      "customer_number",
      "start_time",
      "end_time",
      "duration",
      "unit_consumed",
    ];
    const rows = data.map((item) => [
      item.call_id,
      item.bot_phone,
      item.from_number,
      item.start_time,
      item.end_time,
      item.duration,
      item.unit_consumed,
    ]);

    // Combine headers and rows into CSV format
    const csvContent = [headers, ...rows]
      .map((row) => row.join(","))
      .join("\n");

    return csvContent;
  };

  // Trigger file download
  const downloadCSV = (csvContent, filename) => {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.click();
  };

  return (
    <>
      <div className="main-inner">
        <div className="upperMiddle">
          <h1>Dashboard</h1>
        </div>
        <div className="insights">
          {/* Account Balance  */}
          <div className="accountBal">
            <span className="material-icons-sharp">analytics</span>
            <div className="middle">
              <div className="left">
                <h3>Account Balance</h3>
                <h1>$ {accountBal}</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>{randomNumber}%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Last 24 hours</small>
          </div>
          {/* END OF Account Balance  */}

          {/* On Hold Balance */}
          <div className="onHoldBal">
            <span className="material-icons-sharp">bar_chart</span>
            <div className="middle">
              <div className="left">
                <h3>On-hold Balance</h3>
                <h1>N/A</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>41%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Last 24 hours</small>
          </div>
          {/* On hold Balance  */}

          {/* Total Spent */}
          <div className="expenses">
            <span className="material-icons-sharp">stacked_line_chart</span>
            <div className="middle">
              <div className="left">
                <h3>Total Expense</h3>
                <h1>$ {totalExpense}</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>44%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Last 24 hours</small>
          </div>
          {/* END OF Total Spent  */}
        </div>

        <div className="history">
          <h2>Campaigns</h2>
          <table>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Agent ID</th>
                <th>Agent Name</th>
                <th>Agent Phone</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {bots.map((bot, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{bot.bot_id}</td>
                  <td>{bot.bot_name}</td>
                  <td>{bot.bot_phone}</td>
                  <td className={bot.status ? "true" : "false"}>
                    {bot.status ? "active" : "pending"}
                  </td>
                  <td className="primary">
                    <button
                      className="DownloadLogs"
                      onClick={() => downloadLog(bot.bot_id)}
                    >
                      Download Log
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

