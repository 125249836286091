// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom"; // Import useNavigate
// import "./profile.css";

// export default function Profile() {
//   const navigate = useNavigate(); // Initialize useNavigate
//   const [isEditable, setIsEditable] = useState(false);
//   const [userData, setUserData] = useState({
//     first_name: "",
//     last_name: "",
//     email: "",
//     phone: "",
//     add_1: "",
//     add_2: "",
//     city: "",
//     state: "",
//     zipcode: "",
//   });
//   const [countryCode, setCountryCode] = useState("+1");
//   const [errors, setErrors] = useState({});
//   const [isPasswordPopupVisible, setPasswordPopupVisible] = useState(false);
//   const [oldPassword, setOldPassword] = useState("");  // New state for old password
//   const [newPassword, setNewPassword] = useState("");  // New state for new password
//   const [confirmPassword, setConfirmPassword] = useState(""); // New state for confirm new password
//   const token = localStorage.getItem("access_token");

//   useEffect(() => {
//     const fetchProfile = async () => {
//       if (!token) {
//         // If no token exists, redirect to the homepage
//         navigate("/");
//         return;
//       }

//       try {
//         const response = await fetch("https://devapi.ivoz.ai/users/profile", {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             Accept: "application/json",
//           },
//         });

//         if (!response.ok) {
//           // Handle invalid or expired token
//           if (response.status === 401) {
//             navigate("/"); // Redirect to homepage if unauthorized
//           }
//           console.error("Failed to fetch profile data");
//           return;
//         }

//         const data = await response.json();

//         const fullPhone = data.phone || "";
//         const phoneNumber = fullPhone.slice(-10); 
//         const extractedCountryCode = fullPhone.slice(0, -10) || "+1"; 

//         setUserData({
//           first_name: data.first_name || "",
//           last_name: data.last_name || "",
//           email: data.email || "",
//           phone: phoneNumber,
//           add_1: data.add_1 || "",
//           add_2: data.add_2 || "",
//           city: data.city || "",
//           state: data.state || "",
//           zipcode: data.zipcode || "",
//         });

//         setCountryCode(extractedCountryCode);
//       } catch (error) {
//         console.error("Error fetching profile", error);
//         navigate("/"); // Navigate to homepage if there's an error
//       }
//     };

//     fetchProfile();
//   }, [token, navigate]); // Added navigate as dependency

  // const validateInputs = () => {
  //   const validationErrors = {};
  
  //   // First name validation (only alphabetic characters)
  //   if (!userData.first_name.trim()) {
  //     validationErrors.first_name = "First name is required.";
  //   } else if (!/^[A-Za-z]+$/.test(userData.first_name)) {
  //     validationErrors.first_name = "First name must contain only alphabetic characters.";
  //   }
  
  //   // Other validations...
  //   if (!userData.last_name.trim()) {
  //     validationErrors.last_name = "Last name is required.";
  //   }
  //   if (!userData.email.trim() || !/^\S+@\S+\.\S+$/.test(userData.email)) {
  //     validationErrors.email = "A valid email is required.";
  //   }
  //   if (
  //     !userData.phone ||
  //     userData.phone.length !== 10 ||
  //     !/^\d{10}$/.test(userData.phone)
  //   ) {
  //     validationErrors.phone = "Phone number must be a valid 10-digit number.";
  //   }
  //   if (!userData.city.trim()) {
  //     validationErrors.city = "City is required.";
  //   }
  //   if (!userData.state.trim()) {
  //     validationErrors.state = "State is required.";
  //   }
  //   if (!userData.zipcode.trim() || !/^\d{5,6}$/.test(userData.zipcode)) {
  //     validationErrors.zipcode = "Zipcode must be 5-6 digits.";
  //   }
  
  //   setErrors(validationErrors);
  //   return Object.keys(validationErrors).length === 0;
  // };
  

//   const handleEditClick = () => {
//     setIsEditable(true);
//     alert("You can now edit your profile.");
//   };

//   const handleUpdateClick = async () => {
//     if (!validateInputs()) return;
  
//     try {
//       const fullPhone = `${countryCode}${userData.phone}`;
//       const response = await fetch("https://devapi.ivoz.ai/users/profile", {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           ...userData,
//           phone: fullPhone,
//         }),
//       });
  
//       if (response.ok) {
//         const data = await response.json();
//         alert("Profile updated successfully!");
//         console.log("Profile updated", data);
//         setIsEditable(false);
//       } else {
//         const errorData = await response.json();
//         const errorMessage = errorData?.detail?.[0]?.msg || "Failed to update profile. Please try again.";
//         alert(errorMessage);
  
//         // Check for specific validation error and update the error state
//         if (errorMessage.includes("Name must contain only alphabetic characters")) {
//           setErrors((prevErrors) => ({
//             ...prevErrors,
//             last_name: "Last name must contain only alphabetic characters.",
//           }));
//         }
//       }
//     } catch (error) {
//       alert("An error occurred while updating the profile.");
//       console.error("Error updating profile", error);
//     }
//   };
  
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setUserData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const togglePasswordPopup = () => {
//     setPasswordPopupVisible(!isPasswordPopupVisible); 
//   };

// const handlePasswordSubmit = async () => {
//   if (newPassword !== confirmPassword) {
//     alert("Password did not match");
//     return;
//   }

//   try {
//     const response = await fetch("https://devapi.ivoz.ai/users/change-password", {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         old_password: oldPassword,
//         new_password: newPassword,
//       }),
//     });

//     if (response.ok) {
//       alert("Password changed successfully!");
//       setPasswordPopupVisible(false); // Close the popup
//     } else {
//       const errorData = await response.json();
//       const errorMessage = errorData?.detail?.[0]?.msg || "Failed to change password. Please try again.";
//       alert(errorMessage); // Show the dynamic error message
//     }
//   } catch (error) {
//     console.error("Error changing password", error);
//     alert("An error occurred while changing the password.");
//   }
// };

//   return (
//     <div>
//       <div className="accessButton">
//         <h1>Profile</h1>
//         <div className="updateButton">
//           {isEditable ? (
//             <button onClick={handleUpdateClick}>SAVE</button>
//           ) : (
//             <button onClick={handleEditClick}>EDIT</button>
//           )}
//         </div>
//       </div>

//       <hr />
//       <div className="profileInputs">
//         {Object.entries(userData).map(
//           ([key, value]) =>
//             key !== "phone" && (
//               <div className="profileInner" key={key}>
//                 <label htmlFor={key}>
//                   {key.replace(/_/g, " ").toUpperCase()}
//                 </label>
//                 <br />
//                 <input
//                   type={key === "email" ? "email" : "text"}
//                   name={key}
//                   value={value}
//                   onChange={handleChange}
//                   disabled={!isEditable}
//                 />
//                 {errors[key] && <small className="error">{errors[key]}</small>}
//               </div>
//             )
//         )}

//         <div className="profileInner">
//           <label htmlFor="Phone">Phone Number</label>
//           <div className="countryWithPhonenumber">
//             <select
//               id="country-code"
//               name="countryCode"
//               value={countryCode}
//               onChange={(e) => setCountryCode(e.target.value)}
//               disabled={!isEditable}
//             >
//               <option value="+1">+1</option>
//               <option value="+91">+91</option>
//               <option value="+61">+61</option>
//             </select>
//             <input
//               type="tel"
//               id="phone-number"
//               name="phone"
//               value={userData.phone}
//               onChange={handleChange}
//               pattern="[0-9]{10}"
//               disabled={!isEditable}
//               required
//             />
//           </div>
//           <br/>
//           {errors.phone && <small className="error">{errors.phone}</small>}
//         </div>
//       </div>

//       <hr />

//       <div className="pasword-block">
//         <div className="accessButton right">
//           <button onClick={togglePasswordPopup}>Change Password</button>
//         </div>

//         <div
//           className={`pasword-popup ${isPasswordPopupVisible ? "visible" : ""}`}
//         >
//           <div className="pswd-form">
//             <input
//               type="password"
//               placeholder="Old Password"
//               value={oldPassword}
//               onChange={(e) => setOldPassword(e.target.value)}
//             />
//             <input
//               type="password"
//               placeholder="New Password"
//               value={newPassword}
//               onChange={(e) => setNewPassword(e.target.value)}
//             />
//             <input
//               type="password"
//               placeholder="Confirm New Password"
//               value={confirmPassword}
//               onChange={(e) => setConfirmPassword(e.target.value)}
//             />
//           </div>
//           <div className="accessButton pswd-button right">
//             <button onClick={handlePasswordSubmit}>Submit</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }



import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./profile.css";

export default function Profile() {
  const navigate = useNavigate(); // Initialize useNavigate
  const [isEditable, setIsEditable] = useState(false);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    add_1: "",
    add_2: "",
    city: "",
    state: "",
    zipcode: "",
  });
  const [countryCode, setCountryCode] = useState("+1");
  const [errors, setErrors] = useState({});
  const [isPasswordPopupVisible, setPasswordPopupVisible] = useState(false);
  const [oldPassword, setOldPassword] = useState("");  // New state for old password
  const [newPassword, setNewPassword] = useState("");  // New state for new password
  const [confirmPassword, setConfirmPassword] = useState(""); // New state for confirm new password
  const token = localStorage.getItem("access_token");

  useEffect(() => {
    const fetchProfile = async () => {
      if (!token) {
        // If no token exists, redirect to the homepage
        navigate("/");
        return;
      }

      try {
        const response = await fetch("https://devapi.ivoz.ai/users/profile", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          // Handle invalid or expired token
          if (response.status === 401) {
            navigate("/"); // Redirect to homepage if unauthorized
          }
          console.error("Failed to fetch profile data");
          return;
        }

        const data = await response.json();

        const fullPhone = data.phone || "";
        const phoneNumber = fullPhone.slice(-10); 
        const extractedCountryCode = fullPhone.slice(0, -10) || "+1"; 

        setUserData({
          first_name: data.first_name || "",
          last_name: data.last_name || "",
          email: data.email || "",
          phone: phoneNumber,
          add_1: data.add_1 || "",
          add_2: data.add_2 || "",
          city: data.city || "",
          state: data.state || "",
          zipcode: data.zipcode || "",
        });

        setCountryCode(extractedCountryCode);
      } catch (error) {
        console.error("Error fetching profile", error);
        navigate("/"); // Navigate to homepage if there's an error
      }
    };

    fetchProfile();
  }, [token, navigate]); // Added navigate as dependency

  const validateInputs = () => {
    const validationErrors = {};
  
    // First name validation (only alphabetic characters)
    if (!userData.first_name.trim()) {
      validationErrors.first_name = "First name is required.";
    } else if (!/^[A-Za-z]+$/.test(userData.first_name)) {
      validationErrors.first_name = "First name must contain only alphabetic characters.";
    }
  
    // Other validations...
    if (!userData.last_name.trim()) {
      validationErrors.last_name = "Last name is required.";
    }
    if (!userData.email.trim() || !/^\S+@\S+\.\S+$/.test(userData.email)) {
      validationErrors.email = "A valid email is required.";
    }
    if (
      !userData.phone ||
      userData.phone.length !== 10 ||
      !/^\d{10}$/.test(userData.phone)
    ) {
      validationErrors.phone = "Phone number must be a valid 10-digit number.";
    }
    if (!userData.city.trim()) {
      validationErrors.city = "City is required.";
    }
    if (!userData.state.trim()) {
      validationErrors.state = "State is required.";
    }
    if (!userData.zipcode.trim() || !/^\d{5,6}$/.test(userData.zipcode)) {
      validationErrors.zipcode = "Zipcode must be 5-6 digits.";
    }
  
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
  const handleEditClick = () => {
    setIsEditable(true);
    alert("You can now edit your profile.");
  };

  const handleUpdateClick = async () => {
    if (!validateInputs()) return;

    try {
      const fullPhone = `${countryCode}${userData.phone}`;
      const response = await fetch("https://devapi.ivoz.ai/users/profile", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...userData,
          phone: fullPhone,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        alert("Profile updated successfully!");
        console.log("Profile updated", data);
        setIsEditable(false);
      } else {
        const errorData = await response.json();
        const errorMessage = errorData?.detail?.[0]?.msg || "Failed to update profile. Please try again.";
        alert(errorMessage);

        // Check for specific validation error and update the error state
        if (errorMessage.includes("Name must contain only alphabetic characters")) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            last_name: "Last name must contain only alphabetic characters.",
          }));
        }
      }
    } catch (error) {
      alert("An error occurred while updating the profile.");
      console.error("Error updating profile", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const togglePasswordPopup = () => {
    setPasswordPopupVisible(!isPasswordPopupVisible);
  };

  const handlePasswordSubmit = async () => {
    if (newPassword !== confirmPassword) {
      alert("Password did not match");
      return;
    }

    try {
      const response = await fetch("https://devapi.ivoz.ai/users/change-password", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword,
        }),
      });

      if (response.ok) {
        alert("Password changed successfully!");
        setPasswordPopupVisible(false); // Close the popup
      } else {
        const errorData = await response.json();
        const errorMessage = errorData?.detail?.[0]?.msg || "Failed to change password. Please try again.";
        alert(errorMessage); // Show the dynamic error message
      }
    } catch (error) {
      console.error("Error changing password", error);
      alert("An error occurred while changing the password.");
    }
  };

  return (
    <div>
      <div className="accessButton">
        <h1>Profile</h1>
        <div className="updateButton">
          {isEditable ? (
            <button onClick={handleUpdateClick}>SAVE</button>
          ) : (
            <button onClick={handleEditClick}>EDIT</button>
          )}
        </div>
      </div>

      <hr />
      <div className="profileInputs">
        {Object.entries(userData).map(
          ([key, value]) =>
            key !== "phone" && (
              <div className="profileInner" key={key}>
                <label htmlFor={key}>
                  {key.replace(/_/g, " ").toUpperCase()}
                </label>
                <br />
                <input
                  type={key === "email" ? "email" : "text"}
                  name={key}
                  value={value}
                  onChange={handleChange}
                  disabled={!isEditable}
                />
                {errors[key] && <small className="error">{errors[key]}</small>}
              </div>
            )
        )}

        <div className="profileInner">
          <label htmlFor="Phone">Phone Number</label>
          <div className="countryWithPhonenumber">
            <select
              id="country-code"
              name="countryCode"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              disabled={!isEditable}
            >
              <option value="+1">+1</option>
              <option value="+91">+91</option>
              <option value="+61">+61</option>
            </select>
            <input
              type="tel"
              id="phone-number"
              name="phone"
              value={userData.phone}
              onChange={handleChange}
              pattern="[0-9]{10}"
              disabled={!isEditable}
              required
            />
          </div>
          <br/>
          {errors.phone && <small className="error">{errors.phone}</small>}
        </div>
      </div>

      <hr />

      <div className="pasword-block">
        <div className="accessButton right">
          <button onClick={togglePasswordPopup}>Change Password</button>
        </div>

        <div
          className={`pasword-popup ${isPasswordPopupVisible ? "visible" : ""}`}
        >
          {/* Close button added here */}
          <button
            className="close-popup"
            onClick={() => setPasswordPopupVisible(false)}
          >
            &times; {/* This is the "X" symbol */}
          </button>

          <div className="pswd-form">
            <input
              type="password"
              placeholder="Old Password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className="accessButton pswd-button right">
            <button onClick={handlePasswordSubmit}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
}
