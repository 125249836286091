// import React, { useState } from 'react';

// export default function UpdateKnowledgebase({ agentName, onClose, onSubmit }) {
//   const [uploadType, setUploadType] = useState('pdf');
//   const [trainingData, setTrainingData] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log({ agentName, uploadType, trainingData });
//     onSubmit();
//   };

//   return (
//     <div
//       style={{
//         position: 'fixed',
//         inset: 0,
//         backgroundColor: 'rgba(0, 0, 0, 0.5)',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//       }}
//     >
//       <div
//         style={{
//           backgroundColor: 'white',
//           borderRadius: '8px',
//           padding: '24px',
//           width: '100%',
//           maxWidth: '400px',
//         }}
//       >
//         <h2 style={{ fontSize: '1.25rem', fontWeight: '600', marginBottom: '16px' }}>
//           Update Knowledgebase for {agentName}
//         </h2>
//         <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
//           <div>
//             <label style={{ fontWeight: '500', display: 'block', marginBottom: '8px' }}>Upload Type</label>
//             <div>
//               <label style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
//                 <input
//                   type="radio"
//                   value="pdf"
//                   checked={uploadType === 'pdf'}
//                   onChange={() => setUploadType('pdf')}
//                   style={{ marginRight: '8px' }}
//                 />
//                 Upload PDF
//               </label>
//               <label style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
//                 <input
//                   type="radio"
//                   value="text"
//                   checked={uploadType === 'text'}
//                   onChange={() => setUploadType('text')}
//                   style={{ marginRight: '8px' }}
//                 />
//                 Paste Text
//               </label>
//               <label style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
//                 <input
//                   type="radio"
//                   value="url"
//                   checked={uploadType === 'url'}
//                   onChange={() => setUploadType('url')}
//                   style={{ marginRight: '8px' }}
//                 />
//                 Use URL
//               </label>
//             </div>
//           </div>
//           {uploadType === 'text' && (
//             <textarea
//               placeholder="Paste your text here"
//               value={trainingData}
//               onChange={(e) => setTrainingData(e.target.value)}
//               style={{
//                 width: '100%',
//                 height: '100px',
//                 padding: '8px',
//                 borderRadius: '4px',
//                 border: '1px solid #ddd',
//               }}
//             />
//           )}
//           {uploadType === 'pdf' && (
//             <input
//               type="file"
//               accept=".pdf"
//               onChange={(e) => setTrainingData(e.target.files?.[0]?.name || '')}
//               style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
//             />
//           )}
//           {uploadType === 'url' && (
//             <input
//               type="url"
//               placeholder="Enter URL"
//               value={trainingData}
//               onChange={(e) => setTrainingData(e.target.value)}
//               style={{
//                 width: '100%',
//                 padding: '8px',
//                 borderRadius: '4px',
//                 border: '1px solid #ddd',
//               }}
//             />
//           )}
//           <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
//             <button
//               type="button"
//               onClick={onClose}
//               style={{
//                 padding: '8px 16px',
//                 backgroundColor: 'transparent',
//                 border: '1px solid #ddd',
//                 borderRadius: '4px',
//                 cursor: 'pointer',
//               }}
//             >
//               Cancel
//             </button>
//             <button
//               type="submit"
//               style={{
//                 padding: '8px 16px',
//                 backgroundColor: '#007bff',
//                 color: 'white',
//                 border: 'none',
//                 borderRadius: '4px',
//                 cursor: 'pointer',
//               }}
//             >
//               Submit
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// }

import React, { useState } from "react";
import "./UpdateKnowledgebase.css"; // Import the external CSS file

export default function UpdateKnowledgebase({ agentName, onClose, onSubmit }) {
  const [selectedType, setSelectedType] = useState("");
  const [trainingData, setTrainingData] = useState({
    pdf: "",
    text: "",
    url: "",
  });

  const handleCheckboxChange = (type) => {
    setSelectedType((prev) => (prev === type ? "" : type));
  };

  const handleTrainingDataChange = (type, value) => {
    setTrainingData((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      agentName,
      selectedType,
      trainingData: trainingData[selectedType],
    });
    onSubmit();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">Update Knowledgebase for {agentName}</h2>
        <form onSubmit={handleSubmit} className="modal-form">
          <div className="form-group">
            <label className="form-label">Upload Type</label>
            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  value="pdf"
                  checked={selectedType === "pdf"}
                  onChange={() => handleCheckboxChange("pdf")}
                  className="checkbox-input"
                />
                Upload PDF
              </label>

              <label className="checkbox-label">
                <input
                  type="checkbox"
                  value="text"
                  checked={selectedType === "text"}
                  onChange={() => handleCheckboxChange("text")}
                  className="checkbox-input"
                />
                Paste Text
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  value="url"
                  checked={selectedType === "url"}
                  onChange={() => handleCheckboxChange("url")}
                  className="checkbox-input"
                />
                Use URL
              </label>
            </div>
          </div>

          {selectedType === "text" && (
            <textarea
              placeholder="Paste your text here"
              value={trainingData.text}
              onChange={(e) => handleTrainingDataChange("text", e.target.value)}
              className="text-area"
            />
          )}
          {selectedType === "pdf" && (
            <input
              type="file"
              accept=".pdf"
              onChange={(e) =>
                handleTrainingDataChange("pdf", e.target.files?.[0]?.name || "")
              }
              className="file-input"
            />
          )}
          {selectedType === "url" && (
            <input
              type="url"
              placeholder="Enter URL"
              value={trainingData.url}
              onChange={(e) => handleTrainingDataChange("url", e.target.value)}
              className="url-input"
            />
          )}

          <div className="button-group">
            <button type="button" onClick={onClose} className="cancel-button">
              Cancel
            </button>
            <button type="submit" className="submit-button">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
