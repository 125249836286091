// import React, { useState, useEffect } from "react";
// import "./animated.css";
// import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

// const Animated = () => {
//   const [handLeftStyle, setHandLeftStyle] = useState({});
//   const [handRightStyle, setHandRightStyle] = useState({});
//   const [eyeLeftStyle, setEyeLeftStyle] = useState({});
//   const [eyeRightStyle, setEyeRightStyle] = useState({});
//   const [isPopupVisible, setIsPopupVisible] = useState(false);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [loginError, setLoginError] = useState(null);
//   const [errorMessage, setErrorMessage] = useState("");
  
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Check if tokens are already present in localStorage
//     const accessToken = localStorage.getItem("access_token");
//     const refreshToken = localStorage.getItem("refresh_token");

//     // If tokens are present, remove them and refresh the page
//     if (accessToken || refreshToken) {
//       localStorage.removeItem("access_token");
//       localStorage.removeItem("refresh_token");
//       // window.location.reload(); // Refresh the page
//     }

//     // Show popup after initial render
//     setTimeout(() => {
//       setIsPopupVisible(true);
//     }, 100);
//   }, []);

//   const handleFocusText = () => {
//     setHandLeftStyle({
//       transform: "rotate(0deg)",
//       bottom: "140px",
//       left: "50px",
//       height: "45px",
//       width: "35px",
//     });
//     setHandRightStyle({
//       transform: "rotate(0deg)",
//       bottom: "185px",
//       left: "250px",
//       height: "45px",
//       width: "35px",
//     });
//     setEyeLeftStyle({
//       top: "20px",
//       left: "15px",
//       transition: "top 0.5s ease, left 0.5s ease",
//     });
//     setEyeRightStyle({
//       top: "20px",
//       left: "7px",
//       transition: "top 0.5s ease, left 0.5s ease",
//     });
//   };

//   const handleFocusPassword = () => {
//     setEyeLeftStyle({
//       top: "10px",
//       left: "10px",
//       transition: "top 0.5s ease, left 0.5s ease",
//     });
//     setEyeRightStyle({
//       top: "10px",
//       left: "10px",
//       transition: "top 0.5s ease, left 0.5s ease",
//     });
//     setHandLeftStyle({
//       transform: "rotate(-150deg)",
//       bottom: "215px",
//       left: "105px",
//       height: "90px",
//       width: "40px",
//     });
//     setHandRightStyle({
//       transform: "rotate(150deg)",
//       bottom: "308px",
//       left: "192px",
//       height: "90px",
//       width: "40px",
//     });
//   };

//   const handleLogin = async () => {
//     setLoginError(null); // Reset error message
//     try {
//       const response = await fetch("https://devapi.ivoz.ai/auth/login/", {
//         method: "POST",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           email,
//           password,
//         }),
//       });
  
//       if (response.ok) {
//         const data = await response.json();
//         console.log("Signin successful:", data);
  
//         // Store tokens and role in local storage
//         localStorage.setItem("access_token", data.access_token);
//         localStorage.setItem("refresh_token", data.refresh_token);
//         localStorage.setItem("role", JSON.stringify(data.role));
//         if (data.role.includes("admin")) {
//           navigate("/adminBoard");
//         } else if (
//           data.role.includes("standard_user") &&
//           !data.role.includes("admin")
//         ) {
//           navigate("/userboard");
//         } else {
//           setErrorMessage("Invalid role. Please contact support.");
//         }
//       } else if (response.status === 422) {
//         const errorData = await response.json();
//         const reason =
//           errorData.detail?.[0]?.ctx?.reason ||
//           "Validation error occurred. Please check your input.";
//         setErrorMessage(reason);
//       } else if (response.status === 404) {
//         const errorData = await response.json();
//         if (errorData.detail === "User not found") {
//           setErrorMessage(
//             "Incorrect information. Please check your email and password."
//           );
//         } else {
//           setErrorMessage("Signin failed. Please try again.");
//         }
//       } else if (response.status === 401) {
//         const errorData = await response.json();
//         if (errorData.detail === "Incorrect password") {
//           setErrorMessage("Incorrect password. Please try again.");
//         } else {
//           setErrorMessage("Signin failed. Please try again.");
//         }
//       } else {
//         console.error("Signin failed:", response.statusText);
//         setErrorMessage(
//           "An unexpected error occurred. Please try again later."
//         );
//       }
//     } catch (error) {
//       console.error("Signin error:", error);
//       setErrorMessage(
//         "A network error occurred. Please check your connection and try again."
//       );
//     }
//   };
  


//   useEffect(() => {
//     setTimeout(() => {
//       setIsPopupVisible(true);
//     }, 100);
//   }, []);

//   return (
//     <div className="body-start">
//       <div className={`body-inner ${isPopupVisible ? "popup-visible" : ""}`}>
//         <div className="login">
//           <i className="fa fa-user" aria-hidden="true"></i>
//           <input
//             type="text"
//             onFocus={handleFocusText}
//             placeholder="Email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//           />
//           <br />
//           <br />
//           <i className="fa fa-unlock-alt" aria-hidden="true"></i>
//           <input
//             type="password"
//             onFocus={handleFocusPassword}
//             placeholder="Password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//           />
//           <br />
//           <br />
//           {/* <input type="submit" value="login" className="loginButton" onClick={handleLogin}/> */}
//           <button className="loginButton" type="button" onClick={handleLogin}>
//             Login
//           </button>
//           {loginError && <p className="error-message">{loginError}</p>}
//           {errorMessage && <p className="error-message">{errorMessage}</p>}
//           <br />
//           <div className="forgot-pswd">
//             <button className="btn-link">
//             <Link to="/forget-password-email"> Forgot Password</Link>

//             </button>
//           </div>
//         </div>
//         {/* Panda animation */}
//         <div className="backg">
//           <div className="panda">
//             <div className="earl"></div>
//             <div className="earr"></div>
//             <div className="face">
//               <div className="blshl"></div>
//               <div className="blshr"></div>
//               <div className="eyel">
//                 <div className="eyeball1" style={eyeLeftStyle}></div>
//               </div>
//               <div className="eyer">
//                 <div className="eyeball2" style={eyeRightStyle}></div>
//               </div>
//               <div className="nose">
//                 <div className="line"></div>
//               </div>
//               <div className="mouth">
//                 <div className="m">
//                   <div className="m1"></div>
//                 </div>
//                 <div className="mm">
//                   <div className="m1"></div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="pawl">
//           <div className="p1">
//             <div className="p2"></div>
//             <div className="p3"></div>
//             <div className="p4"></div>
//           </div>
//         </div>
//         <div className="pawr">
//           <div className="p1">
//             <div className="p2"></div>
//             <div className="p3"></div>
//             <div className="p4"></div>
//           </div>
//         </div>
//         <div className="handl" style={handLeftStyle}></div>
//         <div className="handr" style={handRightStyle}></div>
//       </div>
//     </div>
//   );
// };

// export default Animated;



// import React, { useState, useEffect } from "react";
// import "./animated.css";
// import { Link, useNavigate } from "react-router-dom";

// const Animated = () => {
//   const [handLeftStyle, setHandLeftStyle] = useState({});
//   const [handRightStyle, setHandRightStyle] = useState({});
//   const [eyeLeftStyle, setEyeLeftStyle] = useState({});
//   const [eyeRightStyle, setEyeRightStyle] = useState({});
//   const [isPopupVisible, setIsPopupVisible] = useState(false);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");

//   const navigate = useNavigate();

//   useEffect(() => {
//     // Clear any stored tokens when the page loads
//     localStorage.removeItem("access_token");
//     localStorage.removeItem("refresh_token");
//     localStorage.removeItem("role");

//     // Show the popup after a delay
//     setTimeout(() => {
//       setIsPopupVisible(true);
//     }, 100);
//   }, []);

//   // Hand animations for focus on email and password fields
//   const handleFocusText = () => {
//     setHandLeftStyle({
//       transform: "rotate(0deg)",
//       bottom: "140px",
//       left: "50px",
//       height: "45px",
//       width: "35px",
//     });
//     setHandRightStyle({
//       transform: "rotate(0deg)",
//       bottom: "185px",
//       left: "250px",
//       height: "45px",
//       width: "35px",
//     });
//     setEyeLeftStyle({
//       top: "20px",
//       left: "15px",
//       transition: "top 0.5s ease, left 0.5s ease",
//     });
//     setEyeRightStyle({
//       top: "20px",
//       left: "7px",
//       transition: "top 0.5s ease, left 0.5s ease",
//     });
//   };

//   const handleFocusPassword = () => {
//     setEyeLeftStyle({
//       top: "10px",
//       left: "10px",
//       transition: "top 0.5s ease, left 0.5s ease",
//     });
//     setEyeRightStyle({
//       top: "10px",
//       left: "10px",
//       transition: "top 0.5s ease, left 0.5s ease",
//     });
//     setHandLeftStyle({
//       transform: "rotate(-150deg)",
//       bottom: "215px",
//       left: "105px",
//       height: "90px",
//       width: "40px",
//     });
//     setHandRightStyle({
//       transform: "rotate(150deg)",
//       bottom: "308px",
//       left: "192px",
//       height: "90px",
//       width: "40px",
//     });
//   };

//   // Handle form submission
//   const handleLogin = async () => {
//     setErrorMessage(""); // Reset error message

//     if (!email || !password) {
//       setErrorMessage("Please enter both email and password.");
//       return;
//     }

//     try {
//       const response = await fetch("https://devapi.ivoz.ai/auth/login/", {
//         method: "POST",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           email,
//           password,
//         }),
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log("Login successful:", data);

//         // Store tokens and role in local storage
//         localStorage.setItem("access_token", data.access_token);
//         localStorage.setItem("refresh_token", data.refresh_token);
//         localStorage.setItem("role", JSON.stringify(data.role));

//         // Navigate based on role
//         if (data.role.includes("admin")) {
//           navigate("/adminBoard");
//         } else if (data.role.includes("standard_user")) {
//           navigate("/userboard");
//         } else {
//           setErrorMessage("Invalid role. Please contact support.");
//         }
//       } else {
//         const errorData = await response.json();
//         setErrorMessage(errorData.detail || "Login failed. Please try again.");
//       }
//     } catch (error) {
//       console.error("Login error:", error);
//       setErrorMessage("Network error. Please try again later.");
//     }
//   };

//   // Trigger login on pressing Enter
//   const handleKeyDown = (event) => {
//     if (event.key === "Enter") {
//       handleLogin();
//     }
//   };

//   return (
//     <div className="body-start">
//       <div className={`body-inner ${isPopupVisible ? "popup-visible" : ""}`}>
//         <div className="login">
//           <i className="fa fa-user" aria-hidden="true"></i>
//           <input
//             type="text"
//             onFocus={handleFocusText}
//             placeholder="Email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             onKeyDown={handleKeyDown} // Trigger login on Enter
//           />
//           <br />
//           <br />
//           <i className="fa fa-unlock-alt" aria-hidden="true"></i>
//           <input
//             type="password"
//             onFocus={handleFocusPassword}
//             placeholder="Password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             onKeyDown={handleKeyDown} // Trigger login on Enter
//           />
//           <br />
//           <br />
//           <button className="loginButton" type="button" onClick={handleLogin}>
//             Login
//           </button>
//           {errorMessage && <p className="error-message">{errorMessage}</p>}
//           <br />
//           <div className="forgot-pswd">
//             <button className="btn-link">
//               <Link to="/forget-password-email">Forgot Password</Link>
//             </button>
//           </div>
//         </div>
//         {/* Panda animation */}
//         <div className="backg">
//           <div className="panda">
//             <div className="earl"></div>
//             <div className="earr"></div>
//             <div className="face">
//               <div className="blshl"></div>
//               <div className="blshr"></div>
//               <div className="eyel">
//                 <div className="eyeball1" style={eyeLeftStyle}></div>
//               </div>
//               <div className="eyer">
//                 <div className="eyeball2" style={eyeRightStyle}></div>
//               </div>
//               <div className="nose">
//                 <div className="line"></div>
//               </div>
//               <div className="mouth">
//                 <div className="m">
//                   <div className="m1"></div>
//                 </div>
//                 <div className="mm">
//                   <div className="m1"></div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="pawl">
//           <div className="p1">
//             <div className="p2"></div>
//             <div className="p3"></div>
//             <div className="p4"></div>
//           </div>
//         </div>
//         <div className="pawr">
//           <div className="p1">
//             <div className="p2"></div>
//             <div className="p3"></div>
//             <div className="p4"></div>
//           </div>
//         </div>
//         <div className="handl" style={handLeftStyle}></div>
//         <div className="handr" style={handRightStyle}></div>
//       </div>
//     </div>
//   );
// };

// export default Animated;



import React, { useState, useEffect } from "react";
import "./animated.css";
import { Link, useNavigate } from "react-router-dom";

const Animated = () => {
  const [handLeftStyle, setHandLeftStyle] = useState({});
  const [handRightStyle, setHandRightStyle] = useState({});
  const [eyeLeftStyle, setEyeLeftStyle] = useState({});
  const [eyeRightStyle, setEyeRightStyle] = useState({});
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const navigate = useNavigate();

  useEffect(() => {
    // Clear any stored tokens when the page loads
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("role");

    // Show the popup after a delay
    setTimeout(() => {
      setIsPopupVisible(true);
    }, 100);
  }, []);

  // Hand animations for focus on email and password fields
  const handleFocusText = () => {
    setHandLeftStyle({
      transform: "rotate(0deg)",
      bottom: "140px",
      left: "50px",
      height: "45px",
      width: "35px",
    });
    setHandRightStyle({
      transform: "rotate(0deg)",
      bottom: "185px",
      left: "250px",
      height: "45px",
      width: "35px",
    });
    setEyeLeftStyle({
      top: "20px",
      left: "15px",
      transition: "top 0.5s ease, left 0.5s ease",
    });
    setEyeRightStyle({
      top: "20px",
      left: "7px",
      transition: "top 0.5s ease, left 0.5s ease",
    });
  };

  const handleFocusPassword = () => {
    setEyeLeftStyle({
      top: "10px",
      left: "10px",
      transition: "top 0.5s ease, left 0.5s ease",
    });
    setEyeRightStyle({
      top: "10px",
      left: "10px",
      transition: "top 0.5s ease, left 0.5s ease",
    });
    setHandLeftStyle({
      transform: "rotate(-150deg)",
      bottom: "215px",
      left: "105px",
      height: "90px",
      width: "40px",
    });
    setHandRightStyle({
      transform: "rotate(150deg)",
      bottom: "308px",
      left: "192px",
      height: "90px",
      width: "40px",
    });
  };

  // Handle form submission
  const handleLogin = async () => {
    setErrorMessage(""); // Reset error message

    if (!email || !password) {
      setErrorMessage("Please enter both email and password.");
      return;
    }

    setIsLoading(true); // Start loading animation

    try {
      const response = await fetch("https://devapi.ivoz.ai/auth/login/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Login successful:", data);

        // Store tokens and role in local storage
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("refresh_token", data.refresh_token);
        localStorage.setItem("role", JSON.stringify(data.role));

        // Simulate delay for animation
        setTimeout(() => {
          // Navigate based on role
          if (data.role.includes("admin")) {
            navigate("/adminBoard");
          } else if (data.role.includes("standard_user")) {
            navigate("/userboard");
          } else {
            setErrorMessage("Invalid role. Please contact support.");
          }
          setIsLoading(false);
        }, 1000);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.detail || "Login failed. Please try again.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Login error:", error);
      setErrorMessage("Network error. Please try again later.");
      setIsLoading(false);
    }
  };

  // Trigger login on pressing Enter
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="body-start">
      <div className={`body-inner ${isPopupVisible ? "popup-visible" : ""}`}>
        <div className="login">
          <i className="fa fa-user" aria-hidden="true"></i>
          <input
            type="email"
            onFocus={handleFocusText}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown} // Trigger login on Enter
          />
          <br />
          <br />
          <i className="fa fa-unlock-alt" aria-hidden="true"></i>
          <input
            type="password"
            onFocus={handleFocusPassword}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown} // Trigger login on Enter
          />
          <br />
          <br />
          <button
            className="loginButton"
            type="button"
            onClick={handleLogin}
            disabled={isLoading} // Disable button while loading
          >
            {isLoading ? <span className="spinner"></span> : "Login"}
          </button>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <br />
          <div className="forgot-pswd">
            <button className="btn-link">
              <Link to="/forget-password-email">Forgot Password</Link>
            </button>
          </div>
        </div>
        {/* Panda animation */}
        <div className="backg">
          <div className="panda">
            <div className="earl"></div>
            <div className="earr"></div>
            <div className="face">
              <div className="blshl"></div>
              <div className="blshr"></div>
              <div className="eyel">
                <div className="eyeball1" style={eyeLeftStyle}></div>
              </div>
              <div className="eyer">
                <div className="eyeball2" style={eyeRightStyle}></div>
              </div>
              <div className="nose">
                <div className="line"></div>
              </div>
              <div className="mouth">
                <div className="m">
                  <div className="m1"></div>
                </div>
                <div className="mm">
                  <div className="m1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pawl">
          <div className="p1">
            <div className="p2"></div>
            <div className="p3"></div>
            <div className="p4"></div>
          </div>
        </div>
        <div className="pawr">
          <div className="p1">
            <div className="p2"></div>
            <div className="p3"></div>
            <div className="p4"></div>
          </div>
        </div>
        <div className="handl" style={handLeftStyle}></div>
        <div className="handr" style={handRightStyle}></div>
      </div>
    </div>
  );
};

export default Animated;
