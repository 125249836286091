// import React, { useState } from 'react';

// export default function ChatWindow({ agentName, onClose }) {
//   const [messages, setMessages] = useState([]);
//   const [input, setInput] = useState('');

//   const handleSend = () => {
//     if (input.trim()) {
//       setMessages([...messages, { role: 'user', content: input }]);
//       // Simulate bot response
//       setTimeout(() => {
//         setMessages((prev) => [...prev, { role: 'bot', content: `Echo: ${input}` }]);
//       }, 1000);
//       setInput('');
//     }
//   };

//   return (
//     <div
//       style={{
//         position: 'fixed',
//         inset: 0,
//         backgroundColor: 'rgba(0, 0, 0, 0.5)',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//       }}
//     >
//       <div
//         style={{
//           backgroundColor: 'white',
//           borderRadius: '8px',
//           width: '100%',
//           maxWidth: '400px',
//           display: 'flex',
//           flexDirection: 'column',
//           height: '600px',
//         }}
//       >
//         <div style={{ padding: '16px', borderBottom: '1px solid #ddd' }}>
//           <h3 style={{ fontSize: '1.125rem', fontWeight: '600' }}>Chat with {agentName}</h3>
//         </div>
//         <div
//           style={{
//             flexGrow: 1,
//             padding: '16px',
//             overflowY: 'auto',
//             display: 'flex',
//             flexDirection: 'column',
//           }}
//         >
//           {messages.map((message, index) => (
//             <div
//               key={index}
//               style={{
//                 marginBottom: '16px',
//                 textAlign: message.role === 'user' ? 'right' : 'left',
//               }}
//             >
//               <span
//                 style={{
//                   display: 'inline-block',
//                   padding: '8px',
//                   borderRadius: '8px',
//                   backgroundColor: message.role === 'user' ? '#cce4ff' : '#f0f0f0',
//                 }}
//               >
//                 {message.content}
//               </span>
//             </div>
//           ))}
//         </div>
//         <div style={{ padding: '16px', borderTop: '1px solid #ddd', display: 'flex' }}>
//           <input
//             value={input}
//             onChange={(e) => setInput(e.target.value)}
//             onKeyPress={(e) => e.key === 'Enter' && handleSend()}
//             placeholder="Type your message..."
//             style={{
//               flexGrow: 1,
//               marginRight: '8px',
//               padding: '8px',
//               borderRadius: '4px',
//               border: '1px solid #ddd',
//             }}
//           />
//           <button
//             onClick={handleSend}
//             style={{
//               padding: '8px 16px',
//               backgroundColor: '#007bff',
//               color: 'white',
//               border: 'none',
//               borderRadius: '4px',
//               cursor: 'pointer',
//             }}
//           >
//             Send
//           </button>
//         </div>
//         <div style={{ padding: '16px', borderTop: '1px solid #ddd' }}>
//           <button
//             onClick={onClose}
//             style={{
//               width: '100%',
//               padding: '8px 16px',
//               backgroundColor: 'transparent',
//               border: '1px solid #ddd',
//               borderRadius: '4px',
//               cursor: 'pointer',
//             }}
//           >
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useState } from 'react';
import './ChatWindow.css';

export default function ChatWindow({ agentName, onClose }) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, { role: 'user', content: input }]);
      // Simulate bot response
      setTimeout(() => {
        setMessages((prev) => [...prev, { role: 'bot', content: `Echo: ${input}` }]);
      }, 1000);
      setInput('');
    }
  };

  return (
    <div className="chat-overlay">
      <div className="chat-container">
        <div className="chat-header">
          <h3>Chat with {agentName}</h3>
          <button className="chat-close-icon" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="chat-messages">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`chat-message ${message.role === 'user' ? 'chat-user' : 'chat-bot'}`}
            >
              <span className="chat-bubble">{message.content}</span>
            </div>
          ))}
        </div>
        <div className="chat-input-container">
          <input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
            placeholder="Type your message..."
            className="chat-input"
          />
          <button onClick={handleSend} className="chat-send-button">
            Send
          </button>
        </div>
      </div>
    </div>
  );
}
