// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import "./admin.css";
// import "./editUser.css";

// const EditUser = () => {
//   const [query, setQuery] = useState("");
//   const [users, setUsers] = useState([]);
//   const [filteredUsers, setFilteredUsers] = useState([]);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [error, setError] = useState("");
//   const navigate = useNavigate();

//   // Fetch all users on component mount
//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   // Fetch users from the API
//   const fetchUsers = async () => {
//     try {
//       const accessToken = localStorage.getItem("access_token");
//       if (!accessToken) {
//         setError("No access token found in local storage");
//         return;
//       }

//       const response = await fetch(
//         "https://devapi.ivoz.ai/admin/users?page=1&per_page=15",
//         {
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         setUsers(data);
//         setFilteredUsers(data);
//       } else {
//         setError("Failed to fetch users");
//       }
//     } catch (error) {
//       setError("Error fetching users: " + error.message);
//     }
//   };

//   // Update filtered users based on search query (search by email)
//   useEffect(() => {
//     if (query) {
//       setFilteredUsers(
//         users.filter(
//           (user) => user.email.toLowerCase().includes(query.toLowerCase()) // Search by email
//         )
//       );
//     } else {
//       setFilteredUsers(users);
//     }
//   }, [query, users]);

//   // Handle edit user: Populate input fields with user data
//   // Update the handleEditClick function
//   const handleEditClick = async (user) => {
//     try {
//       const accessToken = localStorage.getItem("access_token");
//       const response = await fetch(
//         `https://devapi.ivoz.ai/admin/users/${user.id}`,
//         {
//           method: "POST",
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         setSelectedUser(data); // Set the fetched user data to selectedUser
//       } else {
//         setError("Failed to fetch user details.");
//       }
//     } catch (error) {
//       setError("Error fetching user details: " + error.message);
//     }
//   };

//   // Handle save user: Update user data
//   const handleSaveUser = async () => {
//     try {
//       const accessToken = localStorage.getItem("access_token");
//       const response = await fetch(
//         `https://devapi.ivoz.ai/admin/edit-user/${selectedUser.id}`,
//         {
//           method: "POST",
//           headers: {
//             accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//           body: JSON.stringify(selectedUser),
//         }
//       );
  
//       if (response.ok) {
//         const data = await response.json();
//         console.log("User updated successfully:", data);
//         alert("User updated successfully.");
//         setSelectedUser(null); // Clear input fields
//         fetchUsers(); // Refresh user list
//       } else if (response.status === 422) {
//         const errorData = await response.json();
//         const errorMessages = errorData.detail
//           .map((error) => error.msg)
//           .join("\n"); // Combine all error messages into a single string
//         alert(`Validation Errors:\n${errorMessages}`); // Show alert with errors
//       } else {
//         setError("Failed to update user.");
//       }
//     } catch (error) {
//       setError("Error updating user: " + error.message);
//     }
//   };
  
  

//   // Handle input change for selected user
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setSelectedUser((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   return (
//     <div className="admin-container">
//       {/* Search Bar */}
//       <div className="topBar">
//         <div className="top-bar-search">
//           <input
//             type="text"
//             value={query}
//             onChange={(e) => setQuery(e.target.value)}
//             placeholder="Enter Email"
//             className="searchInput"
//           />
//         </div>
//       </div>
//       <h3>
//         You can edit user you want to edit by clicking on edit column of
//         particular user or you can search user by email{" "}
//       </h3>

//       {/* Table with Users */}
//       {!selectedUser && (
//         <div className="history">
//           <h2>Users</h2>
//           <table>
//             <thead>
//               <tr>
//                 <th>Sr No</th>
//                 <th>User ID</th>
//                 <th>User Name</th>
//                 <th>Email</th>
//                 <th>Edit User</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredUsers.map((user, index) => (
//                 <tr key={user.id}>
//                   <td>{index + 1}</td>
//                   <td>{user.id}</td>
//                   <td>{user.first_name}</td>
//                   <td>{user.email}</td>
//                   <td>
//                     <button
//                       className="editButton"
//                       onClick={() => handleEditClick(user)}
//                     >
//                       Edit
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )}

//       {/* Edit User Form */}
//       {selectedUser && (
//         <div className="edit-users-input">
//           <div className="eu-inputs">
//             {" "}
//             <label>First Name: </label>
//             <input
//               type="text"
//               name="first_name"
//               value={selectedUser.first_name || ""}
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="eu-inputs">
//             <label>Last Name:</label>
//             <input
//               type="text"
//               name="last_name"
//               value={selectedUser.last_name || ""}
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="eu-inputs">
//             <label>Email:</label>
//             <input
//               type="email"
//               name="email"
//               value={selectedUser.email || ""}
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="eu-inputs">
//             <label>Phone:</label>
//             <input
//               type="text"
//               name="phone"
//               value={selectedUser.phone || ""}
//               onChange={handleInputChange}
//             />
//           </div>
//           {/* Additional fields */}
//           <div className="eu-inputs">
//             <label>Address 1:</label>
//             <input
//               type="text"
//               name="add_1"
//               value={selectedUser.add_1 || ""}
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="eu-inputs">
//             <label>Address 2:</label>
//             <input
//               type="text"
//               name="add_2"
//               value={selectedUser.add_2 || ""}
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="eu-inputs">
//             {" "}
//             <label>City:</label>
//             <input
//               type="text"
//               name="city"
//               value={selectedUser.city || ""}
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="eu-inputs">
//             {" "}
//             <label>State:</label>
//             <input
//               type="text"
//               name="state"
//               value={selectedUser.state || ""}
//               onChange={handleInputChange}
//             />
//           </div>
//           <div className="eu-inputs">
//             <label>Zipcode:</label>
//             <input
//               type="number"
//               name="zipcode"
//               value={selectedUser.zipcode || ""}
//               onChange={handleInputChange}
//             />
//           </div>
//         </div>
//       )}
//       <div className="edit-buttons">
//         <button
//           type="button"
//           className="createUserButton"
//           onClick={() => navigate("/adminBoard/Profile")}
//         >
//           Previous
//         </button>
//         <button
//           type="button"
//           className="createUserButton"
//           onClick={handleSaveUser}
//         >
//           Save
//         </button>
//       </div>

//       {/* Error Message */}
//       {error && <p className="error">{error}</p>}
//     </div>
//   );
// };

// export default EditUser;



import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./admin.css";
import "./editUser.css";

const EditUser = () => {
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(8); // Number of rows per page
  const navigate = useNavigate();

  // Fetch all users on component mount
  useEffect(() => {
    fetchUsers();
  }, []);

  // Fetch users from the API
  const fetchUsers = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        setError("No access token found in local storage");
        return;
      }

      const response = await fetch(
        "https://devapi.ivoz.ai/admin/users?page=1&per_page=15",
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUsers(data);
        setFilteredUsers(data);
      } else {
        setError("Failed to fetch users");
      }
    } catch (error) {
      setError("Error fetching users: " + error.message);
    }
  };

  // Update filtered users based on search query (search by email)
  useEffect(() => {
    if (query) {
      setFilteredUsers(
        users.filter(
          (user) => user.email.toLowerCase().includes(query.toLowerCase()) // Search by email
        )
      );
    } else {
      setFilteredUsers(users);
    }
  }, [query, users]);

  // Handle edit user: Populate input fields with user data
  const handleEditClick = async (user) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await fetch(
        `https://devapi.ivoz.ai/admin/users/${user.id}`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSelectedUser(data); // Set the fetched user data to selectedUser
      } else {
        setError("Failed to fetch user details.");
      }
    } catch (error) {
      setError("Error fetching user details: " + error.message);
    }
  };

  // Handle save user: Update user data
  const handleSaveUser = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await fetch(
        `https://devapi.ivoz.ai/admin/edit-user/${selectedUser.id}`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(selectedUser),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("User updated successfully:", data);
        alert("User updated successfully.");
        setSelectedUser(null); // Clear input fields
        fetchUsers(); // Refresh user list
      } else if (response.status === 422) {
        const errorData = await response.json();
        const errorMessages = errorData.detail
          .map((error) => error.msg)
          .join("\n"); // Combine all error messages into a single string
        alert(`Validation Errors:\n${errorMessages}`); // Show alert with errors
      } else {
        setError("Failed to update user.");
      }
    } catch (error) {
      setError("Error updating user: " + error.message);
    }
  };

  // Handle input change for selected user
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Get users for the current page
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="admin-container">
      {/* Search Bar */}
      <div className="topBar">
        <div className="top-bar-search">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Enter Email"
            className="searchInput"
          />
        </div>
      </div>
      <h3>
        You can edit a user you want to edit by clicking on the edit column of
        a particular user or you can search for a user by email
      </h3>

      {/* Table with Users */}
      {!selectedUser && (
        <div className="history">
          <h2>Users</h2>
          <table>
            <thead>
              <tr>
                <th>Sr No</th>
                <th>User ID</th>
                <th>User Name</th>
                <th>Email</th>
                <th>Edit User</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user, index) => (
                <tr key={user.id}>
                  <td>{indexOfFirstUser + index + 1}</td>
                  <td>{user.id}</td>
                  <td>{user.first_name}</td>
                  <td>{user.email}</td>
                  <td>
                    <button
                      className="editButton"
                      onClick={() => handleEditClick(user)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage * rowsPerPage >= filteredUsers.length}
            >
              Next
            </button>
          </div>
        </div>
      )}

      {/* Edit User Form */}
      {selectedUser && (
        <div className="edit-users-input">
          <div className="eu-inputs">
            <label>First Name: </label>
            <input
              type="text"
              name="first_name"
              value={selectedUser.first_name || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="eu-inputs">
            <label>Last Name:</label>
            <input
              type="text"
              name="last_name"
              value={selectedUser.last_name || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="eu-inputs">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={selectedUser.email || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="eu-inputs">
            <label>Phone:</label>
            <input
              type="text"
              name="phone"
              value={selectedUser.phone || ""}
              onChange={handleInputChange}
            />
          </div>
          {/* Additional fields */}
          <div className="eu-inputs">
            <label>Address 1:</label>
            <input
              type="text"
              name="add_1"
              value={selectedUser.add_1 || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="eu-inputs">
            <label>Address 2:</label>
            <input
              type="text"
              name="add_2"
              value={selectedUser.add_2 || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="eu-inputs">
            <label>City:</label>
            <input
              type="text"
              name="city"
              value={selectedUser.city || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="eu-inputs">
            <label>State:</label>
            <input
              type="text"
              name="state"
              value={selectedUser.state || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="eu-inputs">
            <label>Zipcode:</label>
            <input
              type="number"
              name="zipcode"
              value={selectedUser.zipcode || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>
      )}
      <div className="edit-buttons">
        <button
          type="button"
          className="createUserButton"
          onClick={() => navigate("/adminBoard/Profile")}
        >
          Cancel
        </button>
        <button
          type="button"
          className="createUserButton"
          onClick={handleSaveUser}
        >
          Save
        </button>
      </div>

      {/* Error Message */}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default EditUser;
